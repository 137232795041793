/** 
=========================================================
* Vision UI PRO React - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Design and Coded by Simmmple & Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.
*/

import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, useField } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Wizard page components
import Account from "layouts/applications/wizard/components/Account";
import RecipientInfo from "layouts/applications/wizard/components/RecipientInfo";
import COM from "layouts/applications/wizard/components/COM";
import EPostagePackageType from "layouts/applications/wizard/components/ePostagePackageType";
import EPostagePostageType from "../wizard/components/ePostagePostageType";
import Summary from "../wizard/components/Summary";
import { BiBox } from "react-icons/bi";
import { IoCube } from "react-icons/io5";
import { RiInboxFill } from "react-icons/ri";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { setActiveStep } from "redux/Actions/wizard";
import { CircularProgress } from "@mui/material";
import {
  PostVerifyAddress,
  ResetWizard,
  ActPostTopic,
  ActPostJob,
  PostPackageCheckOut,
  PostRecipient,
  PostVerifyAddressSuccess,
} from "redux/Actions/wizard";
import { setLoaderStatus } from "redux/Actions/common";
import { useLocation } from "react-router-dom";
import validations from "layouts/applications/wizard/schemas/document-validations";
import form from "layouts/applications/wizard/schemas/document-form";
import initialValues from "layouts/applications/wizard/schemas/initialValues";
import PreviewDailogBox from "../wizard/components/Summary/PreviewAndCheckout";
import Calendar from "examples/Calendar";
import moment from "moment";
import { Prompt } from "react-router";
import Notification from "components/Notification";
import { RefreshProfile } from "redux/Actions/common";


import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const packageTypeStepper = [
  "General",
  "Recipient",
  "Package",
  "Postage",
  "Certificates",
  "Ship Date",
  "Summary",
];


// const PACKAGES = [
//   { name: "USPS FLAT RATE BOX", displayName: "USPS FLAT RATE PACKAGING", icon: <RiInboxFill size="54px" color="dark" /> },
//   { name: "CUSTOM PACKAGE", displayName: "CUSTOM OR VARIABLE PACKAGING", icon: <IoCube size="54px" color="dark" /> },
//   { name: "BOX OR RIGID PACKAGING", displayName: "BOX OR RIGID PACKAGING", icon: <BiBox size="54px" color="dark" /> },
// ];

const StandardPackages = [
  { name: "USPS FLAT RATE BOX", displayName: "USPS FLAT RATE PACKAGING", icon: <RiInboxFill size="54px" color="dark" /> },
  { name: "CUSTOM PACKAGE", displayName: "CUSTOM OR VARIABLE PACKAGING", icon: <IoCube size="54px" color="dark" /> },
  { name: "BOX OR RIGID PACKAGING", displayName: "BOX OR RIGID PACKAGING", icon: <BiBox size="54px" color="dark" /> },
];

const HazardousPackages = [
  { name: "CUSTOM PACKAGE", displayName: "CUSTOM OR VARIABLE PACKAGING", icon: <IoCube size="54px" color="dark" /> },
  { name: "BOX OR RIGID PACKAGING", displayName: "BOX OR RIGID PACKAGING", icon: <BiBox size="54px" color="dark" /> },
];

function getSteps(sendType) {
  if (sendType === "document") {
    return ["Upload PDF", "Document"];
  } else if (sendType === "package") {
    return;
  } else {
    return packageTypeStepper;
  }
}

function getStepContent(
  stepIndex,
  getPacksgeBtnState,
  getSendNextBtnState,
  documentKey,
  documentHash,
  handleOnEditMailing,
  pageCount,
  formData
) {




  switch (stepIndex) {
    case 0:
      return <Account
        formData={formData}
        handleOnEditMailing={handleOnEditMailing} documentKey={documentKey}
        sendType={'ePostage'}
      />
    case 1:
      return <RecipientInfo formData={formData} />;
    case 2:
      return <EPostagePackageType
        formData={formData}
        // packageList={PACKAGES}
        packageList={formData?.values?.IsHazardous ? HazardousPackages : StandardPackages}
        getPacksgeBtnState={getPacksgeBtnState}
      />
    case 3:
      return <EPostagePostageType hazardous={formData?.values?.IsHazardous} formData={formData} onSubmit={getSendNextBtnState} />;
    case 4:
      return <COM formData={formData} />;
    case 5:
      return (
        <div>
          <Calendar
            initialView="dayGridMonth"
            selectable={true}
            select={({ start, end, view }) => {
              if (start < new Date(new Date().toDateString())) {
                view.calendar.unselect()
                return false;
              }
              var nextDate = new Date();
              nextDate.setDate(nextDate.getDate() + 6);

              if (start > new Date(nextDate.toDateString())) {
                view.calendar.unselect()
                return false;
              }

              const _date = moment(start).format("MM/DD/YYYY");
              formData.setFieldValue("shipDate", _date);

              getSendNextBtnState('next');
            }}
          // dateClick={(e) => {
          // const date = moment(e.date).format("MM/DD/YYYY");
          // formData.setFieldValue("shipDate", date);
          // }}
          />

          <VuiBox mt={0.75}>
            <VuiTypography component="div" variant="caption" color="error">
              {formData?.errors?.shipDate?.length > 0 && (
                <div className="input-feedback">{formData?.errors?.shipDate}</div>
              )}
            </VuiTypography>
          </VuiBox>
        </div>
      );
    case 6:
      return <Summary formData={formData} documentKey={documentKey} />;
    default:
      return null;
  }
}



function ePostageWizard(props) {
  const {
    active,
    loader,
    SelectedTopic,
    PackageCheckOutRes,
    SelectedPackagePricingData,
    RecordManagement,
    packageData,
    CertificateOfMailing,
  } = props;
  const { formId, formField } = form;
  const location = useLocation();
  let { send_type, activeTab } = useParams();
  const currentValidation = validations[active];
  const [jobErorr, setJobError] = useState("");
  const [topicError, setTopicError] = useState("");
  const [recipientError, setRecipientError] = useState("");
  const [checkoutError, setCheckoutError] = useState("");
  const [open, setOpen] = useState(false);
  const [jobSuccess, setJobSuccess] = useState(false);
  const [topicSuccess, setTopicSuccess] = useState(false);
  const [recipientSuccess, setRecipientSuccess] = useState(false);
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);
  const [jobHash, setJobHash] = useState("");
  const [topicHash, setTopicHash] = useState("");
  const [formValues, setFormValues] = useState({});
  const [modeType, setModeType] = useState("");
  const [btnState, setBtnState] = useState(loader);
  const [sendType, setSendType] = useState("");
  const [steps, setSteps] = useState([]);
  const [fileResponse, setFileResponse] = useState(null);
  const [notrizedDocumentHash, setNotrizedDocumentHash] = useState("");
  const [documentKey, setDocumentKey] = useState(null);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const history = useHistory();
  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [IsNFT, setIsNFT] = useState(false);
  const [initialData, setInitialData] = useState(initialValues);

  const handleOnEditMailing = () => {
    setTopicHash("");
  };

  useEffect(() => {
    if (send_type) {
      setSendType(send_type);
      var steps = getSteps(send_type);
      setSteps(steps);
      props.setLoaderStatus(false);
    }
  }, [send_type]);

  useEffect(() => {
    props.ResetWizard();
    if (activeTab) {
      props.setActiveStep(parseInt(activeTab));
    }
    if (location?.state?.Id) {
      props.setActiveStep(location?.state?.step);
      setNotrizedDocumentHash(location?.state?.Id);
      setDocumentKey(history?.location?.state?.documentKey);

      if (location?.state?.IsNFT) {
        setIsNFT(true);
        initialValues["IsNFT"] = true;
        setInitialData(initialValues)
      }
      else {
        setInitialData(initialValues)
      }
      if (location?.state?.pageCount) {
        setPageCount(location?.state?.pageCount);
      }
    }
  }, [location, activeTab, send_type]);
  const isLastStep = active === packageTypeStepper.length - 1;

  const handleBack = () => {
    props.setActiveStep(active - 1);
  };

  const getSendNextBtnState = (status) => {
    if (status == 'next') {
      props.setActiveStep(active + 1);
    }
  };

  const getPacksgeBtnState = (status) => {
    if (active === 4) {
      setBtnState(status);
    }
  };

  const HandlePackageCheckout = (values, jobHash, ModeType) => {
    props.setLoaderStatus(true);
    setCheckoutLoading(true);
    const payload = {
      JobHash: jobHash,
      Mode: ModeType,
      LabelType: "4X6LABEL",
      PostageType: SelectedPackagePricingData?.ServiceCode,
      ShipDate: moment(values?.shipDate).format("MM/DD/YYYY"),
      Container: values.flatRate,
      PriceType: packageData?.PriceType,
      SubscriptionName: "custom",
      Width: values?.customWidth,
      Length: values?.customLength,
      Height: values?.customHeight,
    };
    props.PostPackageCheckOut(payload).then((res) => {
      if (!res.status) {
        setCheckoutLoading(false);
        setCheckoutError(res.error);
      } else {
        setModeType(ModeType);
        setCheckoutSuccess(true);
        setCheckoutLoading(false);
        props.setLoaderStatus(false);
      }
      props?.RefreshProfile();
    });
  };

  const HandleRecipient = (values, jobHash) => {
    const payload = {
      JobHash: jobHash,
      CompanyName: values?.recCompanyName, //# optional by default it's empty
      Phone: "", //# optional by default it's empty
      Email: "", //# optional by default it's empty
      PersonName: values?.recipientName, //# optional by default it's empty
      AddressLine1: values?.recAddress1,
      AddressLine2: values?.recAddress2, //# optional by default it's empty
      Country: "", //# optional by default it's empty
      City: values?.recCity,
      State: values?.recState,
      Zip: values?.recZip,
    };

    props.PostRecipient(payload).then((res) => {
      if (!res.status) {
        setRecipientError(res.error);
      } else {
        console.log("run checkout");
        HandlePackageCheckout(values, jobHash, "Preview");
        setRecipientSuccess(true);
      }
    });
  };

  const HandleJobCreate = (values, topicHash) => {
    const payload = {
      UniqueJobId: "",
      PreviousTopicHash: values?.topicHash,
      TopicHash: topicHash,
      ShipperHash: localStorage.ShipperHash,
      PostageType: SelectedPackagePricingData?.ServiceCode,
      FromName: values?.senderName,
      FromCompany: values?.CompanyName,
      FromAddressLine1: values?.address1,
      FromAddressLine2: values?.address2,
      FromPhone: values.phone.substr(values.phone.length - 10),
      FromCity: values?.city,
      FromState: values?.state,
      FromZip: values?.zip,
      FromCountry: values?.country,
      Weight: values?.packageWeight, // required
      ExtraInfo: "",
      COMCode: CertificateOfMailing?.Code == 'No' ? '' : CertificateOfMailing?.Code,
      StorageClass: `${RecordManagement?.Code}`,
      ApiService: "ePostage",
      NotarizedDocumentHash: notrizedDocumentHash,
      IsHazardous: values.IsHazardous,
    };
    props.ActPostJob(payload).then((res) => {
      if (!res.status) {
        setJobError(res.error);
      } else {
        setJobHash(res.data.JobHash);
        HandleRecipient(values, res.data.JobHash);
        setJobSuccess(true);
      }
    });
  };

  const HandleTopicCreate = (values) => {
    const payload = {
      ShipperHash: localStorage.ShipperHash,
      Name: values.caseName,
      Subject: values.mailingName,
      // ...SelectedTopic,
    };
    props.ActPostTopic(payload).then((res) => {
      props.setLoaderStatus(false);
      if (!res.status) {
        setTopicError(res.error);
      } else {
        setTopicSuccess(true);
        setTopicHash(res.data.TopicHash);
        HandleJobCreate(values, res.data.TopicHash);
      }
    });
  };

  const submitForm = async (values) => {
    setOpen(true);
    setFormValues(values);

    if (topicHash) {
      await setTopicSuccess(true);
    } else {
      await setTopicSuccess(false);
    }

    if (topicHash && !jobSuccess) {
      setTopicSuccess(true);
      HandleJobCreate(values, topicHash);
    } else if (jobSuccess && !recipientSuccess) {
      HandleRecipient(values, jobHash);
    } else if (jobSuccess && recipientSuccess && !checkoutSuccess) {
      HandlePackageCheckout(values, jobHash, "Preview");
    } else if (jobSuccess && recipientSuccess && checkoutSuccess) {
      setOpen(true);
    } else {
      if (topicSuccess) {
        HandleJobCreate(values, topicHash);
      } else {
        if (!topicHash) {
          HandleTopicCreate(values);
        }
      }
    }
  };

  const [verificationDialog, setVerificationDialog] = useState(false);
  const [verifiedAddress, setVerifiedAddress] = useState(null);
  const [addressVerified, setAddressVerified] = useState(false);

  const toggleVerificationDialog = () => {
    setVerificationDialog(prevCheck => !prevCheck);
  };

  const verifyAddress = (values) => {
    // IsAddressChanged

    var reciepientInfo = {
      Address1: values.recAddress1,
      Address2: values.recAddress2,
      City: values.recCity,
      CompanyName: values.recCompanyName,
      PersonName: values.recipientName,
      Phone: values.phone,
      State: values.recState,
      Zip: values.recZip,
      Provider: "usps",
    };

    props.PostVerifyAddress(reciepientInfo).then((res) => {
      props.setLoaderStatus(false);
      if (res.status === 200) {
        if (res.data.IsAddressChanged == false) {
          // Entered address is correct.
          setMessageOpen(true);
          setMessage("Address verified!");
          setMessageType("success");
          props.setActiveStep(active + 1);
        }
        else {
          // entered address is incorrect need to change.
          setVerifiedAddress(res.data);
          toggleVerificationDialog();
        }
      } else {
        try {
          var error = `${res.response.data.error}`;
          setMessageOpen(true);
          setMessage(error);
          setMessageType("error");
          return false;
        } catch {
          setMessageOpen(true);
          setMessage("network error!");
          setMessageType("error");
        }
      }
    });
  }

  const correctAddress = (setFieldValue) => {
    setFieldValue("recAddress1", verifiedAddress.AddressLine1);
    setFieldValue("recAddress2", verifiedAddress.AddressLine2);
    setFieldValue("recCity", verifiedAddress.City);
    setFieldValue("recState", verifiedAddress.State);
    setFieldValue("recZip", verifiedAddress.Zip5);
    toggleVerificationDialog();
    setAddressVerified(true);
    props.setActiveStep(active + 1);
  }



  const handleSubmit = (values, actions) => {
    // setFormValues(values);

    if (isLastStep) {
      submitForm(values);
    }
    else if (active === 1) {
      verifyAddress(values);
      // if (!addressVerified) {
      //   verifyAddress(values);
      // }
      // else {
      //   props.setActiveStep(active + 1);
      // }
    } else {
      props.setActiveStep(active + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const setActiveStep = (step) => {
    props.setActiveStep(step);
  };

  const ResetCheckOut = () => {
    setTopicSuccess(false);
    setCheckoutSuccess(false);
    setRecipientSuccess(false);
    setJobSuccess(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMessageOpen(false);
  };

  setTimeout(() => {
    if (messageOpen) {
      setMessageOpen(false);
    }
  }, 6000);

  return (
    <>
      {
        messageType ? <Notification
          open={messageOpen}
          type={messageType}
          message={message}
          handleClose={handleClose}
          vertical="bottom"
          horizontal="center"
        /> : null
      }

      <Prompt
        when={
          active > 0 &&
          (topicSuccess == false ||
            checkoutSuccess == false ||
            recipientSuccess == false ||
            jobSuccess == false)
        }
        message="Your unsaved changes will be lost if you navigate away from this page.
         Please OK to move out of this page. Press CANCEL to continue editing"
      />

      <VuiBox pt={3} pb={10} style={{ width: "80%", margin: "0px auto" }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={12} lg={12}>
            <VuiBox mt={1} mb={4} textAlign="center">
              <VuiBox mb={1}>
                <VuiTypography variant="h3" fontWeight="bold" color="dark">
                  ePOSTAGE
                </VuiTypography>
              </VuiBox>
            </VuiBox>
            <Formik
              initialValues={initialData}
              validationSchema={currentValidation}
              onSubmit={(values, actions) => {
                setTopicHash(values?.topicHash || topicHash);
                handleSubmit(values, actions);
              }}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setFieldError,
                setTouched
              }) => (
                <div>
                  <Dialog onClose={toggleVerificationDialog} maxWidth="md" fullWidth={true} open={verificationDialog}>
                    <DialogTitle>Address Modified By USPS Verification Service</DialogTitle>
                    <DialogContent style={{ width: "100%", padding: 10 }}>
                      <div style={{ paddingBottom: 10 }}>Address Line 1: {verifiedAddress?.AddressLine1}</div>

                      <div style={{ paddingBottom: 10 }}>Address Line 2: {verifiedAddress?.AddressLine2}</div>

                      <div style={{ paddingBottom: 10 }}>City: {verifiedAddress?.City}</div>

                      <div style={{ paddingBottom: 10 }}>State: {verifiedAddress?.State}</div>

                      <div>Zip: {verifiedAddress?.Zip5}</div>
                    </DialogContent>
                    <DialogActions>
                      <VuiButton variant="gradient" color="light" onClick={toggleVerificationDialog}>
                        Edit Manually
                      </VuiButton>

                      <VuiButton variant="gradient" color="dark" onClick={() => {
                        correctAddress(setFieldValue)
                      }}>
                        Use Verified Address
                      </VuiButton>
                    </DialogActions>
                  </Dialog>

                  <Form id={formId} autoComplete="off">
                    <Card>
                      <VuiBox p={2}>
                        <VuiBox>
                          {getStepContent(
                            active,
                            getPacksgeBtnState,
                            getSendNextBtnState,
                            documentKey,
                            notrizedDocumentHash,
                            handleOnEditMailing,
                            pageCount,
                            {
                              values,
                              touched,
                              formField,
                              errors,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError,
                              setTouched
                            }
                          )}
                          <VuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
                            {active === 0 ? (
                              <VuiBox />
                            ) : (
                              <VuiButton variant="gradient" color="light" onClick={handleBack}>
                                back
                              </VuiButton>
                            )}

                            {/* {active > 0 ? ( */}
                            <VuiButton
                              variant="contained"
                              color="info"
                              type="submit"
                              disabled={loader}
                            >
                              {isLastStep ? "Preview and checkout" : "next"}{" "}
                              {loader ? <>&nbsp;&nbsp;<CircularProgress color={"error"} size={16} /></> : ""}
                            </VuiButton>
                            {/* ) : null} */}
                          </VuiBox>
                        </VuiBox>
                      </VuiBox>
                    </Card>
                  </Form>
                </div>

              )}
            </Formik>
          </Grid>
        </Grid>
      </VuiBox>
      <VuiBox textAlign="end" mt={2} mb={2}>
        <PreviewDailogBox
          url={PackageCheckOutRes?.LabelPreviewUrl}
          SelectedTopic={SelectedTopic}
          TopicSuccess={topicSuccess}
          JobResponseSuccess={jobSuccess}
          PackageCheckOutSuccess={checkoutSuccess}
          RecipientSuccess={recipientSuccess}
          TopicError={topicError}
          RecipientError={recipientError}
          JobResponseError={jobErorr}
          PackageCheckOutError={checkoutError}
          loader={loader}
          handlePackageCheckout={HandlePackageCheckout}
          formData={props.formData}
          open={open}
          toggle={() => setOpen(!open)}
          jobHash={jobHash}
          values={formValues}
          modeType={modeType}
          loading_page={checkoutLoading}
          ResetCheckOut={ResetCheckOut}
          setActiveStep={setActiveStep}
          sendType={sendType}
        />
      </VuiBox>
    </>
  );
}

export default connect(
  (state) => ({
    active: state.WizardStore.activeStep,
    senderInfoData: state.WizardStore.sender_info,
    isAddressVerify: state.WizardStore.is_address_verify,
    AddressVerifyRes: state.WizardStore.address_verify_res,
    loader: state.CommonStore.loader,
    recipientInfo: state.WizardStore.recipient_info,
    packageType: state.WizardStore.packageType,
    SelectedTopic: state.WizardStore.SelectedTopic,
    TopicSuccess: state.WizardStore.TopicSuccess,
    JobResponseSuccess: state.WizardStore.JobResponseSuccess,
    PackageCheckOutRes: state.WizardStore.PackageCheckOutRes,
    PackageCheckOutSuccess: state.WizardStore.PackageCheckOutSuccess,
    RecipientSuccess: state.WizardStore.RecipientSuccess,
    SelectedPackagePricingData: state.WizardStore.SelectedPackagePricingData,
    RecordManagement: state.WizardStore.RecordManagement,
    CertificateOfMailing: state.WizardStore.CertificateOfMailing,

    TopicError: state.WizardStore.TopicError,
    RecipientError: state.WizardStore.RecipientError,
    JobResponseError: state.WizardStore.JobResponseError,
    PackageCheckOutError: state.WizardStore.PackageCheckOutError,
    JobResponse: state.WizardStore.JobResponse,
    packageData: state.WizardStore.packageData,
  }),
  {
    setActiveStep,
    PostVerifyAddress,
    ResetWizard,
    ActPostTopic,
    ActPostJob,
    PostPackageCheckOut,
    PostRecipient,
    setLoaderStatus,
    PostVerifyAddressSuccess,
    RefreshProfile,
  }
)(ePostageWizard);
