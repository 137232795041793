import React from "react";
import { Switch } from "react-router-dom";
import Wizard from "layouts/applications/wizard";
import Sent from "layouts/applications/Send";
import Default from "layouts/dashboards/default";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import ProfileEdit from "layouts/pages/profile/profile-edit";
import SignInCover from "layouts/authentication/sign-in/cover";
import SessionPrepare from "layouts/authentication/prepare-session";
import DefaultPage from "layouts/authentication/default-page";
import PublicRoute from "components/Common/PublicRoute";
import PrivateRoute from "components/Common/PrivateRoute";
import ePostageWizard from "layouts/applications/e-postage-wizard";
import eNotraizedDocument from "layouts/applications/e-notraize-document";
import nftWizard from "layouts/applications/nft-wizard";
import PrintToMailWizard from "layouts/applications/print-to-mail-wizard";
import Minted from "layouts/applications/nft-wizard/Minted";
// import NftList from "layouts/applications/nft-wizard/nft-list";

import NFTListV2 from "layouts/applications/nft-wizard/nft-list/nft-list";


import TrackMailing from "layouts/applications/track-wizard";

import Verify from "layouts/applications/verify-wizard/Verify";
import Verified from "layouts/applications/verify-wizard/Verified";
import DocumentsV2 from "layouts/applications/documents/documents";
import ContactList from "layouts/applications/contacts";
// import ContactListV2 from "layouts/applications/contacts/index-v2";
import DocumentVerification from "layouts/applications/StripeDocumentVarification";
import SendEpostage from "layouts/applications/nft-wizard/SendEpostage";
import AddressBookCreate from "layouts/applications/contacts/components/addressBookCreate";
import ManageSubscription from "layouts/pages/manage-subscription";
import OnBoarding from "layouts/pages/onboarding.js";
import DispatchQueue from "layouts/applications/dispatch-queue";
import CreditAddSuccessFailure from "layouts/pages/AvailableCredit/add-credit-success";
import RateCalculator from "layouts/applications/rates";
import PrivacyPolicy from "layouts/pages/privacy-polocy";
import TermsAndCondition from "layouts/pages/terms-and-condition";
import TransactionHistory from "layouts/applications/transaction-history";
import CreditHistory from "layouts/applications/credit-history";


const routesConfig = [
  {
    exact: true,
    isPrivateRoute: true,
    path: "/add-credit-response",
    component: CreditAddSuccessFailure,
  },
  { exact: true, isPrivateRoute: true, path: "/dashboard", component: Default },
  { exact: true, isPrivateRoute: true, path: "/profile", component: ProfileOverview },
  { exact: true, isPrivateRoute: true, path: "/applications/wizard/", component: Wizard },
  { exact: true, isPrivateRoute: true, path: "/applications/send", component: Sent },
  { exact: true, isPrivateRoute: true, path: "/applications/:send_type/type", component: Wizard },
  { exact: true, isPrivateRoute: true, path: "/profile/edit", component: ProfileEdit },
  { exact: true, isPrivateRoute: false, path: "/prepare-session", component: SessionPrepare },
  { exact: true, isPrivateRoute: false, path: "/", component: DefaultPage },
  { exact: true, isPrivateRoute: false, path: "/sign-in", component: SignInCover },
  { exact: true, isPrivateRoute: false, path: "/rate-calculator", component: RateCalculator },
  { exact: true, isPrivateRoute: true, path: "/privacy", component: PrivacyPolicy },
  { exact: true, isPrivateRoute: true, path: "/terms", component: TermsAndCondition },
  {
    exact: true,
    isPrivateRoute: true,
    path: "/send/e-postage",
    component: ePostageWizard,
  },
  { exact: true, isPrivateRoute: true, path: "/send/print-to-mail", component: PrintToMailWizard },
  { exact: true, isPrivateRoute: true, path: "/nft/create", component: nftWizard },
  { exact: true, isPrivateRoute: true, path: "/send/minted", component: Minted },
  { exact: true, isPrivateRoute: true, path: "/send/Send-ePostage", component: SendEpostage },
  // { exact: true, isPrivateRoute: true, path: "/send/my-document", component: MyDocument },
  // { exact: true, isPrivateRoute: true, path: "/send/view-document", component: ViewDocument },
  // { exact: true, isPrivateRoute: true, path: "/send/notarized", component: Notarized },
  // { exact: true, isPrivateRoute: true, path: "/send/track", component: Track },
  { exact: true, isPrivateRoute: true, path: "/track/print-to-mail", component: TrackMailing },
  { exact: true, isPrivateRoute: true, path: "/track/e-postage", component: TrackMailing },
  { exact: true, isPrivateRoute: true, path: "/track/nft", component: TrackMailing },
  { exact: true, isPrivateRoute: true, path: "/track/all-mails", component: TrackMailing },


  { exact: true, isPrivateRoute: true, path: "/verify/blockchain", component: Verify },
  { exact: true, isPrivateRoute: true, path: "/verified/blockchain", component: Verified },
  // { exact: true, isPrivateRoute: true, path: "/document-list", component: OrderList },
  { exact: true, isPrivateRoute: true, path: "/contacts/list", component: ContactList },
  { exact: true, isPrivateRoute: true, path: "/contacts/create", component: AddressBookCreate },
  {
    exact: true,
    isPrivateRoute: true,
    path: "/manage-subscription",
    component: ManageSubscription,
  },
  { exact: true, isPrivateRoute: true, path: "/onboarding", component: OnBoarding },
  // { exact: true, isPrivateRoute: true, path: "/available-credit", component: AvailableCredit },
  { exact: true, isPrivateRoute: true, path: "/transaction-history", component: TransactionHistory },
  { exact: true, isPrivateRoute: true, path: "/credits-history", component: CreditHistory },
  { exact: true, isPrivateRoute: true, path: "/dispatch-queue", component: DispatchQueue },
  {
    exact: true,
    isPrivateRoute: true,
    path: "/contacts/edit/:AddressBookId/:AddressId",
    component: AddressBookCreate,
  },

  {
    exact: true,
    isPrivateRoute: true,
    path: "/stripe-document-verification",
    component: DocumentVerification,
  },
  { exact: true, isPrivateRoute: true, path: "/nft/list", component: NFTListV2 },

  {
    exact: true,
    isPrivateRoute: true,
    path: "/documents/e-notarize",
    component: eNotraizedDocument,
  },
  { exact: true, isPrivateRoute: true, path: "/documents/list", component: DocumentsV2 },

  { exact: true, isPrivateRoute: false, path: "", component: SessionPrepare },
];
const AppRoute = () => {
  return (
    <>
      <Switch>
        {routesConfig.map((singleRoute, index) => {
          if (singleRoute.isPrivateRoute === true) {
            return <PrivateRoute key={index} {...singleRoute} />;
          }
          return <PublicRoute key={index} {...singleRoute} />;
        })}
      </Switch>
    </>
  );
};

export default AppRoute;
