// @flow
import React, { useEffect } from "react";
import Spin from "components/Spin";
import { useHistory } from "react-router-dom";
import PageLayout from "examples/LayoutContainers/PageLayout";
import moment from "moment";
import { setShiper } from "redux/Actions/profile";
import { setLogin } from "redux/Actions/common";
import { connect } from "react-redux";
import getAccessToken from "api/getCognitoAccessToken";
import { setProfileStatusCode, GetProfile, setProfileDataRes } from "redux/Actions/common";
import dpslogo from "../../../assets/images/dps-logo.png";

const index = (props) => {
  const { setProfileStatusCode, GetProfile, setProfileDataRes } = props;
  const history = useHistory();
  const Redirect = (path) => {
    history.push(path);
  };

  useEffect(() => {
    var currentDateTime = new Date();
    var dateTime = moment.unix(localStorage.token_exp).isSameOrBefore(moment(currentDateTime));
    if (localStorage.getItem("access_token") === null || dateTime) {
      getAccessToken(
        Redirect,
        props.setShiper,
        props.setLogin,
        setProfileStatusCode,
        GetProfile,
        setProfileDataRes,
        () => {
          if (localStorage.id_token !== null && localStorage.id_token !== undefined) {
            history.push("/dashboard");
          }
        }
      );
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (localStorage.id_token !== null && localStorage.id_token !== undefined) {
      history.push("/dashboard");
    }
  });
  return (
    <PageLayout>
        
      <div style={{textAlign:'center', marginTop:'10%'}}>
      <img src={dpslogo} alt="dps logo" style={{ width: '150px', height: '130px' }} />  
      </div>
      <div className="example" style={{marginTop:"0"}}>
        <Spin tip="Preparing your session" spinning={true} />
      </div>
    </PageLayout>
  );
};

export default connect(
  (state) => ({
    Profile: state.CommonStore.Profile,
  }),
  {
    setShiper,
    setLogin,
    setProfileStatusCode,
    GetProfile,
    setProfileDataRes,
  }
)(index);
