

import { useEffect, useState } from "react";


import { connect } from "react-redux";
import { ActGetAddressBook } from "redux/Actions/wizard";
import Spin from "components/Spin";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Alert, Snackbar } from "@mui/material";
import Notification from "components/Notification";


import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Row from './row';

function ContactList(props) {
    const { ActGetAddressBook, AddressBook, loading } = props;
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");

    const history = useHistory();

    useEffect(() => {
        ActGetAddressBook();
    }, []);

    const DeleteAddressBook = (AddressId) => {
        var url = `${process.env.REACT_APP_API_BASE_URL}/addressbook/${localStorage.ShipperHash
            }/${-1}/address/${AddressId}`;

        const headers = {
            Authorization: `Bearer ${localStorage.id_token}`,
            AuthType: "token",
        };
        axios
            .delete(url, {
                headers: headers,
            })
            .then((res) => {
                if (res.status === 200) {
                    ActGetAddressBook();
                    setOpen(true);
                    setMessage("Address Deleted!");
                    setMessageType("success");
                }
            })
            .catch((error) => {
                setOpen(true);
                setMessage(error.message);
                setMessageType("error");
            });
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    setTimeout(() => {
        if (open) {
            setOpen(false);
        }
    }, 6000);

    const onRowClick = (row, event) => {
        if (event == 'edit') {
            history.push(
                `/contacts/edit/${row?.AddressBookId}/${row?.AddressId}`,
                row
            );
        }
        else if (event == 'delete') {
            if (confirm("Are you sure want to delete Address?") === true) {
                DeleteAddressBook(row?.AddressId);
            }
        }
    }

    return (
        <div>
            <Paper>
                {
                    messageType != "" ? <Notification open={open} type={messageType} message={message} handleClose={handleClose} /> : null
                }
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Contacts
                            </Typography>

                            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => {
                                history.push("/contacts/create");
                            }}>
                                Add New Contact
                            </Button>

                        </Toolbar>
                    </AppBar>
                </Box>
                <Spin tip="Loading..." spinning={loading}>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Company</TableCell>
                                    <TableCell>Address Line 1</TableCell>
                                    <TableCell>Address Line 2</TableCell>
                                    <TableCell>City</TableCell>
                                    <TableCell>Zip</TableCell>
                                    <TableCell>Wallet Address</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {AddressBook?.map((row, index) => (
                                    <Row key={index} row={row} onRowClick={onRowClick} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Spin>
            </Paper>
        </div>

    );
}

export default connect(
    (state) => ({
        AddressBook: state.WizardStore.AddressBook,
        loading: state.CommonStore.loader,
    }),
    { ActGetAddressBook }
)(ContactList);
