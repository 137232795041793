

// @mui material components
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import FormField from "../Components/FormField";
import VuiSelect from "components/VuiSelect";
import { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setShiper } from "redux/Actions/profile";
import { GetState } from "api/StateGet";
import { UpdateLoadSenderInfo } from "redux/Actions/wizard";
import {
  setProfileStatusCode,
  GetProfile,
  setProfileDataRes,
  setLoaderStatus,
} from "redux/Actions/common";
import Spin from "components/Spin";
import Notification from "components/Notification";

import { PostVerifyAddress } from "redux/Actions/wizard"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';



function Overview(props) {
  const { Profile, GetProfile, setProfileDataRes, setLoaderStatus } = props;
  const options = GetState();
  const [loadProfile, setLoadProfile] = useState(false);
  const [payload, setPayload] = useState({
    Email: "",
    CompanyName: "",
    PersonName: "",
    AddressLine1: "APT 7",
    AddressLine2: "120 SILLS CT",
    City: "capitoal",
    State: "California",
    Country: "",
    Zip: "95010",
    Phone: "+917985157568",
  });
  const [PersonName, setPersonName] = useState("");
  const [Email, setEmail] = useState(localStorage.username);
  const [CompanyName, setCompanyName] = useState("");
  const [AddressLine1, setAddressLine1] = useState("");
  const [AddressLine2, setAddressLine2] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [Zip, setZip] = useState("");
  const [Phone, setPhone] = useState("");
  const [email_error, setEmailError] = useState(false);
  const [name_error, setNameError] = useState(false);
  const [city_error, setCityError] = useState(false);
  const [state_error, setStateError] = useState(false);
  const [zip_error, setZipError] = useState(false);
  const [address1_error, setAdd1Error] = useState(false);
  const [ShipperHash, setShipperHash] = useState("");
  const [phone_error, setPhoneError] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [referalCode, setReferalCode] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (Profile) {
      setPersonName(Profile?.PersonName);
      setPhone(Profile?.Phone);
      setEmail(Profile?.Email);
      setState(Profile?.State);
      setCity(Profile?.City);
      setZip(Profile?.Zip);
      setAddressLine1(Profile?.AddressLine1);
      setAddressLine2(Profile?.AddressLine2);
      setCompanyName(Profile?.CompanyName);
      setShipperHash(Profile?.ShipperHash);
    }
    else {
      setEmail(localStorage.username);
      setPhone(localStorage.phone_number.substr(localStorage.phone_number.length - 10));
    }
  }, [Profile]);

  const handleNameChange = (e) => {
    setPersonName(e.target.value);
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleAddressLine1Change = (e) => {
    setAddressLine1(e.target.value);
  };
  const handleAddressLine2Change = (e) => {
    setAddressLine2(e.target.value);
  };
  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };
  const handleCityChange = (e) => {
    setCity(e.target.value);
  };
  const handleStateChange = (data) => {
    setState(data.value);
  };
  const handleZipChange = (e) => {
    setZip(e.target.value);
  };

  const validate = () => {
    var status = true;
    if (Email) {
      setEmailError(false);
    } else {
      setEmailError(true);
      status = false;
    }
    if (Phone.toString().length === 10) {
      setPhoneError(false);
    } else {
      setPhoneError(true);
      status = false;
    }
    if (PersonName) {
      setNameError(false);
    } else {
      setNameError(true);
      status = false;
    }
    if (City) {
      setCityError(false);
    } else {
      setCityError(true);
      status = false;
    }
    if (State) {
      setStateError(false);
    } else {
      setStateError(true);
      status = false;
    }
    if (Zip) {
      setZipError(false);
    } else {
      setZipError(true);
      status = false;
    }
    if (AddressLine1) {
      setAdd1Error(false);
    } else {
      setAdd1Error(true);
      status = false;
    }
    return status;
  };

  const verifyAddress = (values, method) => {
    setLoadProfile(true);
    setAddressVerificationError('');

    var reciepientInfo = {
      Address1: values.AddressLine1,
      Address2: values.AddressLine2,
      City: values.City,
      CompanyName: values.CompanyName,
      PersonName: values.PersonName,
      Phone: values.Phone,
      State: values.State,
      Zip: values.Zip,
      Provider: "usps"
    };

    props.PostVerifyAddress(reciepientInfo).then((res) => {
      props.setLoaderStatus(false);
      if (res.status === 200) {
        if (res.data.IsAddressChanged == false) {
          saveProfile(values, method);
        }
        else {
          setVerifiedAddress(res.data);
          toggleVerificationDialog();
        }
      }
      else {
        try {
          var error = `${res.response.data.error}`;
          setAddressVerificationError(error);
        } catch {
          setMessageOpen(true);
          setMessage("network error!");
          setMessageType("error");
        }
      }
    }).finally(() => {
      setLoadProfile(false);
    });
  }

  const [verificationDialog, setVerificationDialog] = useState(false);
  const [verifiedAddress, setVerifiedAddress] = useState(null);
  const [addressVerified, setAddressVerified] = useState(false);
  const [addressVerificationError, setAddressVerificationError] = useState('');

  const toggleVerificationDialog = () => {
    setVerificationDialog(prevCheck => !prevCheck);
  };
  const correctAddress = () => {
    setAddressLine1(verifiedAddress.AddressLine1);
    setAddressLine2(verifiedAddress.AddressLine2);
    setCity(verifiedAddress.City);
    setState(verifiedAddress.State);
    setZip(verifiedAddress.Zip5);
    setCompanyName(verifiedAddress.FirmName);

    toggleVerificationDialog();
    setAddressVerified(true);

    if (ShipperHash) {
      handleUpdate(verifiedAddress);
    }
    else {
      handleSubmit(verifiedAddress);
    }
  }

  const saveProfile = (data, method) => {
    setLoadProfile(true);
    var url = `${process.env.REACT_APP_API_BASE_URL}/shippers`;
    let obj = null;
    data["ShipperHash"] = ShipperHash;

    var headers = {
      Authorization: `Bearer ${localStorage.id_token}`,
      AuthType: "token",
    };

    if (method == 'POST') {
      obj = axios.post(url, data, {
        headers: headers,
      });
    }
    else {
      obj = axios.put(url, data, {
        headers: headers,
      });
    }

    obj.then((res) => {
      if (res.status === 200) {
        GetProfile()
          .then((profile_res) => {
            if (profile_res.status === 200) {
              setProfileDataRes(profile_res.data[0]);
            }
          })
          .finally(() => {
            setLoaderStatus(false);
          });
        setLoadProfile(false);
        if (res.data?.PersonName != null && res.data?.PersonName != undefined) {
          localStorage.setItem("PersonName", res.data?.PersonName);
        }
        if (res.data?.ShipperHash != null && res.data?.ShipperHash != undefined) {
          localStorage.setItem("ShipperHash", res.data.ShipperHash);
        }
        props.setShiper({ ShipperHash: res.data.ShipperHash });
        props.setProfileStatusCode(res.status);
        history.push("/profile");
      }
    })
      .catch((error) => {
        setLoadProfile(false);
        setMessageOpen(true);
        setMessage(error.message);
        setMessageType("error");
      }).finally(() => {
        setLoadProfile(false);
      });
  }

  const handleSubmit = (_address) => {
    var data = payload;

    data["VendorHash"] = "IXlQJAFdIf8KqRXQgdzy3w";
    data["AddressLine1"] = AddressLine1;
    data["AddressLine2"] = AddressLine2;
    data["PersonName"] = PersonName;
    data["Email"] = Email;
    data["Phone"] = Phone;
    data["City"] = City;
    data["State"] = State;
    data["CompanyName"] = CompanyName;
    data["Zip"] = Zip;
    data["ReferralCode"] = referalCode;

    if (_address != null && _address != undefined) {
      data["AddressLine1"] = _address.AddressLine1;
      data["AddressLine2"] = _address.AddressLine2;
      data["City"] = _address.City;
      data["State"] = _address.State;
      data["CompanyName"] = _address.FirmName;
      data["Zip"] = _address.Zip5;
    }

    if (validate()) {
      setLoadProfile(true);
      verifyAddress(data, "POST");
    }
  };

  const handleUpdate = (_address) => {
    var data = payload;
    data["VendorHash"] = "IXlQJAFdIf8KqRXQgdzy3w";
    data["AddressLine1"] = AddressLine1;
    data["AddressLine2"] = AddressLine2;
    data["PersonName"] = PersonName;
    data["Email"] = Email;
    data["Phone"] = Phone;
    data["City"] = City;
    data["State"] = State;
    data["CompanyName"] = CompanyName;
    data["Zip"] = Zip;

    if (_address != null && _address != undefined) {
      data["AddressLine1"] = _address.AddressLine1;
      data["AddressLine2"] = _address.AddressLine2;
      data["City"] = _address.City;
      data["State"] = _address.State;
      data["CompanyName"] = _address.FirmName;
      data["Zip"] = _address.Zip5;
    }

    var headers = {
      Authorization: `Bearer ${localStorage.id_token}`,
      AuthType: "token",
    };

    if (validate()) {
      verifyAddress(data, "PUT")
      // setLoadProfile(true);
      // var url = `${process.env.REACT_APP_API_BASE_URL}/shippers`;
      // data["ShipperHash"] = ShipperHash;
      // data["AddressLine1"] = AddressLine1;
      // data["AddressLine2"] = AddressLine2;
      // data["PersonName"] = PersonName;
      // data["Email"] = Email;
      // data["Phone"] = Phone;
      // data["City"] = City;
      // data["State"] = State;
      // data["CompanyName"] = CompanyName;
      // data["Zip"] = Zip;

      // var headers = {
      //   Authorization: `Bearer ${localStorage.id_token}`,
      //   AuthType: "token",
      // };

      // // if (validate()) {
      // var url = `${process.env.REACT_APP_API_BASE_URL}/shippers`;
      // axios
      //   .put(url, data, {
      //     headers: headers,
      //   })
      //   .then((res) => {
      //     if (res.status === 200) {
      //       GetProfile()
      //         .then((profile_res) => {
      //           if (profile_res.status === 200) {
      //             setProfileDataRes(profile_res.data[0]);
      //           }
      //         })
      //         .finally(() => {
      //           setLoaderStatus(false);
      //         });
      //       props.UpdateLoadSenderInfo(false);
      //       setLoadProfile(false);
      //       props.setProfileStatusCode(res.status);
      //       // history.push("/profile");
      //     }
      //   })
      //   .catch((error) => {
      //     setMessageOpen(true);
      //     setMessage(error.message);
      //     setMessageType("error");
      //   });
    }
  };

  return (
    <Spin tip="Loading..." spinning={loadProfile}>
      {
        messageType != '' ? <Notification
          open={messageOpen}
          type={messageType}
          message={message}
        /> : null
      }


      <Dialog onClose={toggleVerificationDialog} maxWidth="md" fullWidth={true} open={verificationDialog}>
        <DialogTitle>Address Modified By USPS Verification Service</DialogTitle>
        <DialogContent style={{ width: "100%", padding: 10 }}>
          <div style={{ paddingBottom: 10 }}>Address Line 1: {verifiedAddress?.AddressLine1}</div>

          <div style={{ paddingBottom: 10 }}>Address Line 2: {verifiedAddress?.AddressLine2}</div>

          <div style={{ paddingBottom: 10 }}>City: {verifiedAddress?.City}</div>

          <div style={{ paddingBottom: 10 }}>State: {verifiedAddress?.State}</div>

          <div>Zip: {verifiedAddress?.Zip5}</div>
        </DialogContent>
        <DialogActions>
          <VuiButton variant="gradient" color="light" onClick={toggleVerificationDialog}>
            Edit Manually
          </VuiButton>

          <VuiButton variant="gradient" color="dark" onClick={() => {
            correctAddress()
          }}>
            Use Verified Address
          </VuiButton>
        </DialogActions>
      </Dialog>


      <VuiBox mt={props?.isPopup ? 0 : 2} mb={props?.isPopup ? 0 : 4}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={props?.isPopup ? 12 : 9}>
            <Paper style={{ padding: 20 }} elevation={props?.isPopup ? 0 : 1}>
              {props?.isPopup ? null : (
                <h5>Profile Edit</h5>
              )}

              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <FormField
                    type="text"
                    value={PersonName}
                    name="PersonName"
                    label="Name"
                    onChange={handleNameChange}
                    placeholder="Enter Your Name"
                    error={name_error}
                  />
                  {name_error ? <small style={{ color: "red" }}>Required Field!</small> : ""}
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormField
                    type="text"
                    value={Phone}
                    name="Phone"
                    disabled
                    label="Phone"
                    onChange={handlePhoneChange}
                    placeholder="Enter Your Phone Number"
                    error={phone_error}
                  />
                  {phone_error ? <small style={{ color: "red" }}>Required Field!</small> : ""}
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormField
                    type="email"
                    value={Email}
                    disabled
                    name="Email"
                    label="Email"
                    onChange={handleEmailChange}
                    placeholder="Enter Your Email"
                    error={email_error}
                  />
                  {email_error ? <small style={{ color: "red" }}>Required Field!</small> : ""}
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormField
                    type="text"
                    value={CompanyName}
                    name="CompanyName"
                    label="Company Name"
                    onChange={handleCompanyNameChange}
                    placeholder="Enter Company Name"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormField
                    type="text"
                    value={AddressLine1}
                    name="AddressLine1"
                    label="Address Line 1"
                    onChange={handleAddressLine1Change}
                    placeholder="Enter Address Line 1"
                    error={address1_error}
                  />
                  {address1_error ? <small style={{ color: "red" }}>Required Field!</small> : ""}
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormField
                    type="text"
                    value={AddressLine2}
                    name="AddressLine2"
                    label="Address Line 2"
                    onChange={handleAddressLine2Change}
                    placeholder="Enter Address Line 2"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormField
                    type="text"
                    value={City}
                    name="City"
                    label="City"
                    onChange={handleCityChange}
                    placeholder="Enter Your City"
                    error={city_error}
                  />
                  {city_error ? <small style={{ color: "red" }}>Required Field!</small> : ""}
                </Grid>
                <Grid item xs={12} md={4}>
                  <>
                    <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <VuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                        color="dark"
                      >
                        State
                      </VuiTypography>
                    </VuiBox>
                    <VuiSelect
                      type="text"
                      value={options.find((obj) => obj.value === State)}
                      name="State"
                      label="State"
                      menuPlacement="top"
                      onChange={handleStateChange}
                      placeholder="Choose Your State"
                      options={options}
                      error={state_error}
                    />
                    {state_error ? <small style={{ color: "red" }}>Required Field!</small> : ""}
                  </>
                </Grid>
                <Grid item xs={12} md={4} py={2}>
                  <FormField
                    type="text"
                    value={Zip}
                    name="Zip"
                    label="Zip"
                    onChange={handleZipChange}
                    placeholder="Enter Your Zip Code"
                    error={zip_error}
                  />
                  {zip_error ? <small style={{ color: "red" }}>Required Field!</small> : ""}
                </Grid>
                {
                  props?.Profile == null ? (
                    <Grid item xs={12} md={8} py={2}>
                      <FormField
                        type="text"
                        value={referalCode}
                        name="referalCode"
                        label="Referal Code (Optional)"
                        onChange={(e) => {
                          setReferalCode(e.target.value);
                        }}
                        placeholder="Do you have Referal Code?"
                      />
                    </Grid>
                  ) : null
                }

                <Grid item xs={12} md={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ color: 'red', fontSize: 12 }}>{addressVerificationError}</span>
                    {ShipperHash ? (
                      <VuiButton size="medium" color="primary" onClick={() => {
                        handleUpdate()
                      }}>
                        Update Profile
                      </VuiButton>
                    ) : (
                      <VuiButton size="medium" color="primary" onClick={() => {
                        handleSubmit()
                      }}>
                        Create Profile
                      </VuiButton>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </VuiBox>

    </Spin>
  );
}

export default connect(
  (state) => ({
    Profile: state.CommonStore.Profile,
    ShipperHash: state.ProfileStore.ShipperHash,
  }),
  // (state) => ({
  //   ShipperHash: state.ProfileStore.ShipperHash,
  // }),
  {
    setShiper,
    UpdateLoadSenderInfo,
    setProfileStatusCode,
    GetProfile,
    setProfileDataRes,
    setLoaderStatus,
    PostVerifyAddress,
  }
)(Overview);
