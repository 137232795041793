import { Alert, Snackbar } from "@mui/material";

function Notification(props) {
  let _vertical = props?.vertical ? props?.vertical : "top";
  let _horizontal = props?.horizontal ? props?.horizontal : "right";

  return (
    <Snackbar
      anchorOrigin={{ vertical: _vertical, horizontal: _horizontal }}
      open={props.open}
      autoHideDuration={3000}
      key={"top" + "right"}
    >
      <Alert onClose={props.handleClose} severity={props.type} sx={{ width: "100%" }}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}

export default Notification;
