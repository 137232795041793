import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import VuiButton from "components/VuiButton";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import VuiInput from "components/VuiInput";
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { connect } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import { GetProfile, setProfileDataRes, setLoaderStatus } from "redux/Actions/common";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function AvailableCreditsPopUp(props) {
  const { GetProfile, setProfileDataRes, setLoaderStatus } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [timer, setTimer] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAmountChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setAmount(parseInt(e.target.value));
    }
  }

  React.useEffect(() => {
    return () => {
      if (timer != null) {
        window.clearInterval(timer);
      }
    }
  }, []);

  const createPaymentIntent = async (e) => {
    e.preventDefault();
    setLoading(true);

    let url = `${process.env.REACT_APP_API_BASE_URL}/stripe/create-session`;


    var headers = {
      Authorization: `Bearer ${localStorage.id_token}`,
      authtype: "token",
    };
    var payload = {
      Amount: amount,
      Mode: "charge",
      CancelUrl: window.location.origin + "/add-credit-response?status=failure",
      SuccessUrl: window.location.origin + "/add-credit-response?status=success",
      ShipperHash: localStorage.ShipperHash
    }

    axios
      .post(url, payload, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setOpen(false);
          // var newwindow = window.open(res.data.SessionUrl, "_blank", 'location=yes,height=670,width=620,scrollbars=yes,status=yes');
          window.open(res.data.SessionUrl);
          localStorage.setItem("credit_added", "adding");

          let timer = window.setInterval(() => {
            if (timer != null) {
              if (localStorage?.credit_added == 'added') {
                window.clearInterval(timer);
                setLoading(false);
                localStorage.setItem("credit_added", "");
                GetProfile().then(res => {
                  setLoaderStatus(false);
                  if (res.status == 200) {
                    setProfileDataRes(res.data[0]);
                  }
                })
              }
            }
          }, 1000);
        }
      });
  };


  return (
    <div>
      <VuiButton variant="contained" size="small" color="success" style={{ fontSize: 13, padding: '0.5rem 1rem' }} onClick={handleClickOpen}>
        <AccountBalanceWalletIcon style={{ width: '1.2rem', height: '1.2rem' }} />
        {
          loading ? <CircularProgress size={16} /> : "Buy Credits"
        }
        <ChevronRightIcon style={{ width: '1.2rem', height: '1.2rem' }} />
      </VuiButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md" fullWidth={true}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          &nbsp;&nbsp;Buy Credits
        </BootstrapDialogTitle>
        <DialogContent style={{ borderBotton: 0, borderTop: '1px solid #ddd' }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <VuiInput placeholder="$10" onChange={handleAmountChange} />
            </Grid>
            <Grid item xs={4}><VuiButton variant="contained" onClick={createPaymentIntent} color="success">
              {
                loading ? <CircularProgress size={16} /> : "Buy Credits"
              }
            </VuiButton></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default connect(
  (state) => ({
    Profile: state.CommonStore.Profile,
  }),
  {
    GetProfile,
    setProfileDataRes,
    setLoaderStatus
  }
)(AvailableCreditsPopUp);