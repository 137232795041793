
import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import VuiButton from "components/VuiButton";
import Spin from "components/Spin";

import axios from "axios";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Row from "./row";

function AddressBook(props) {
    const { openGrid, handleCloseGrid } = props;
    const [open, setOpen] = useState(openGrid);
    const [loading, setLoading] = useState(false);

    const [addressBookList, setAddressBookList] = useState([]);

    useEffect(() => {
        setOpen(openGrid);
    }, [openGrid]);

    const handleClose = () => {
        handleCloseGrid(false);
        setOpen(false);
    };

    useEffect(() => {
        setLoading(true);
        var url = `${process.env.REACT_APP_API_BASE_URL}/addressbook/${localStorage.ShipperHash
            }/${-1}/address`;
        const headers = {
            Authorization: `Bearer ${localStorage.id_token}`,
            AuthType: "token",
        };
        axios
            .get(url, { headers: headers })
            .then((res) => {
                if (res.status === 200) {
                    setAddressBookList(res.data);
                }
            })
            .catch((err) => {
            }).finally(() => {
                setLoading(false);
            });
    }, []);

    const onRowClick = (row) => {
        props?.onSelect(row);
    }

    return (
        <Dialog onClose={handleClose} maxWidth="lg" fullWidth={true} open={open}>
            <DialogTitle>Address Book</DialogTitle>

            <DialogContent style={{ width: "100%", padding: 10 }}>
                <Spin tip={"Loading..."} spinning={loading}>
                    <TableContainer component={"div"}>
                        <Table aria-label="collapsible table" padding="none" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" style={{ width: 120 }}>Name</TableCell>
                                    <TableCell>Company</TableCell>
                                    <TableCell>Address Line 1</TableCell>
                                    <TableCell>Address Line 2</TableCell>
                                    <TableCell>City</TableCell>
                                    <TableCell>State</TableCell>
                                    <TableCell>Zip</TableCell>
                                    <TableCell>Wallet Address</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {addressBookList.map((row, index) => (
                                    <Row key={index} row={row} onRowClick={onRowClick} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Spin>
            </DialogContent>
            <DialogActions>
                <VuiButton onClick={handleClose} variant="contained" color="info">
                    Close
                </VuiButton>
            </DialogActions>
        </Dialog>
    );
}

export default AddressBook;
