

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Card from "@mui/material/Card";
// import BlockChain from "assets/images/blockchain.png";
import BlockChain from "assets/images/icons/chain_icon.png";
import Link from "@mui/material/Link";
import { connect } from "react-redux";
import { resetVerifyWizard } from "redux/Actions/verify";
import { useHistory, useLocation } from "react-router-dom";
import { getVerificationApi } from "redux/Actions/verify";
import { setLoaderStatus } from "redux/Actions/common";
import Spin from "components/Spin";
import VuiButton from "components/VuiButton";


function Verified(props) {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("VERIFIED");
  const [documentHash, setDocumentHash] = useState(null);
  const [color, setColor] = useState("success");



  useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const vfc = params.get("vf");
    if (vfc !== "true") {
      history.push("/verify/blockchain");
      props;
    }
  }, [location]);


  useEffect(() => {
    if (location?.state?.Id) {
      debugger;
      if (location?.state?.title) {
        setTitle(location?.state?.title);
        setDocumentHash(location?.state?.Id);
      }
      let data = {
        NftToken: location?.state?.Id,
      }
      setLoading(true);
      props?.getVerificationApi(data).then((res) => {
        if (res.success == false) {
          setTitle("No Records Found.");
          setColor("error");
        }
        setLoading(false);
      });
    }

  }, [])

  return (
    <Spin tip="Loading..." spinning={loading}>
      <VuiBox style={{ width: "80%", margin: "auto", flexDirection: 'column', alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: 65 }}>
        <Grid item xs={12} pb={2} justifyContent="center">
          <VuiTypography variant="h3" color={color} textAlign="center" fontWeight="bold">
            {loading ? "" : title}
          </VuiTypography>
          {
            color == "error" ? (

              <VuiButton
                variant="contained"
                color="info"
                onClick={() => {
                  history.push("/verify/blockchain");
                }}
              >
                Try Again
              </VuiButton>) : null
          }
        </Grid>
        {props?.verify_response?.map((verifyDetail, index) => {
          return (
            <div key={index} style={{ width: '100%' }}>
              <Card style={{ overflow: 'initial' }}>
                <Grid container spacing={1} justifyContent="center">
                  <Grid item xs={12} pb={2}>
                    <VuiTypography textAlign="center" variant="body1">
                      <strong>
                        {verifyDetail.Blockchain === "-1"
                          ? "DPS PRIVATE NFT"
                          : "NFT COA VERIFICATION PAGE"}
                      </strong>
                    </VuiTypography>
                  </Grid>
                  <Grid item xs={12} md={4} textAlign="center">
                    <div style={{ width: 250, height: 300, backgroundImage: `url(${verifyDetail.Path})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain' }}>
                      {/* FMoW8Oq3cloJHw8IiyPGUo */}
                    </div>
                    {/* <img src={verifyDetail.Path} style={{ maxWidth: "80%", marginTop: '5px' }} /> */}

                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                      {verifyDetail.Blockchain !== "-1" ? (
                        <Grid item xs={12} md={6}>
                          <Grid item xs={12} md={12}>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>CREATOR:</strong> {verifyDetail.Creator}{" "}
                            </VuiTypography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>NAME:</strong> {verifyDetail.DocumentName}{" "}
                            </VuiTypography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>DOCUMENT ID:</strong> {verifyDetail.DocumentHash}{" "}
                            </VuiTypography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>NFT ID:</strong> {verifyDetail.TokenId}{" "}
                            </VuiTypography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>DESCRIPTION:</strong> {verifyDetail.Description}{" "}
                            </VuiTypography>
                          </Grid>
                          <Grid item xs={12} md={12} mt={1}>
                            <VuiTypography variant="h6">CURRENT OWNER</VuiTypography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>CURRENT OWNER:</strong> {verifyDetail.CurrentOwner.Name}
                            </VuiTypography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>ADDRESS:</strong> {verifyDetail.CurrentOwner.Address}
                            </VuiTypography>
                          </Grid>
                          <Grid item xs={12} md={12} style={{ display: "flex" }}>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>DATE OF TRANSFER:</strong>{" "}
                              {new Date(verifyDetail.CurrentOwner.TransferredDate).toLocaleDateString(
                                "en-US"
                              )}
                            </VuiTypography>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={6}>
                          <Grid item xs={12} md={12}>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>SHIPPER:</strong> {verifyDetail.ShipperHash}{" "}
                            </VuiTypography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>RECIPIENT:</strong> {verifyDetail.RecipientHash}{" "}
                            </VuiTypography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>SHIPPED DATE:</strong>{" "}
                              {new Date(verifyDetail.ShipDate).toLocaleDateString("en-US")}{" "}
                            </VuiTypography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>DELIVERY DATE:</strong>{" "}
                              {new Date(verifyDetail.DeliveryDate).toLocaleDateString("en-US")}{" "}
                            </VuiTypography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>ITEM SHIPPED:</strong> {verifyDetail.Creator}{" "}
                            </VuiTypography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>DESCRIPTION:</strong> {verifyDetail.Description}{" "}
                            </VuiTypography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>Tags:</strong> {verifyDetail.Tags}{" "}
                            </VuiTypography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>NFT ID:</strong> {verifyDetail.DocumentHash}{" "}
                            </VuiTypography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>POSTAGE TOKEN:</strong> {verifyDetail.JobHash}{" "}
                            </VuiTypography>
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={12} md={6}>
                        <VuiTypography textAlign="left" variant="body2">
                          <strong>MINT DATE:</strong>{" "}
                          {new Date(verifyDetail.MintedDate).toLocaleDateString("en-US")}{" "}
                        </VuiTypography>
                        <br />
                        <VuiTypography textAlign="left" variant="body2">
                          <strong>BLOCKCHAIN NETWORK</strong>{" "}
                        </VuiTypography>
                        {verifyDetail.Blockchain !== "-1" ? (
                          <>
                            <VuiTypography>
                              <img src={BlockChain} style={{ height: 30, width: 30 }} />{" "}
                              <Link href={verifyDetail.PolyscanView} target="_blank" variant="body2">
                                {verifyDetail.NetworkName}
                              </Link>{" "}
                            </VuiTypography>
                            <br />
                            <VuiTypography variant="subtitle2">Proof of transfer </VuiTypography>
                            <VuiTypography textAlign="left" variant="body2">
                              <strong>Transaction hash:</strong>{" "}
                              <Link href={verifyDetail.PolyscanView} target="_blank" variant="body2">
                                {verifyDetail.TransactionHash}
                              </Link>{" "}
                            </VuiTypography>
                          </>
                        ) : (
                          <>
                            <VuiTypography>
                              <img src={BlockChain} style={{ height: 30, width: 30 }} />{" "}
                              <span
                                style={{ fontSize: "0.875rem", fontWeight: "400", lineHeight: "1.5" }}
                              >
                                {verifyDetail.NetworkName}
                              </span>
                            </VuiTypography>
                            <VuiTypography>
                              <Link href={verifyDetail.UspsTracking} target="_blank" variant="body2">
                                {"TRACK LIVE ON USPS"}
                              </Link>{" "}
                            </VuiTypography>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
              {index !== props.verify_response.length - 1 ? (
                <Grid
                  item
                  xs={12}
                  pt={3}
                  pb={3}
                  textAlign="center"
                  style={{
                    width: 2,
                    backgroundColor: "#ddd",
                    margin: "0 auto",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <img src={BlockChain} style={{ height: 50, width: 50 }} />
                </Grid>
              ) : null}
            </div>
          );
        })}
        {
          documentHash && loading == false ? (
            <div style={{ marginTop: 20 }}>
              <VuiButton
                variant="contained"
                color="info"
                onClick={() => {
                  history.push("/send/e-postage", {
                    step: 0,
                    Id: documentHash,
                  });
                }}
              >
                Ship Now
              </VuiButton>
              &nbsp;&nbsp;
              <VuiButton
                variant="contained"
                color="info"
                onClick={() => {
                  history.push('/nft/list')
                }}
              >
                Ship Later
              </VuiButton>
            </div>
          ) : null
        }
      </VuiBox>
    </Spin>
  );
}

function mapStateToProps(state) {
  const { verify_response } = state.VerifyStore;
  return {
    verify_response
  };
}

export default connect(mapStateToProps, { resetVerifyWizard, getVerificationApi, setLoaderStatus })(Verified);
