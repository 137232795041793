import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import VuiTagInput from "components/VuiTagInput";
import axios from "axios";


import Web3 from "web3";
import abi from "./abi";

let nftTokenAddress = "";
let marketplaceAddress = "";
let contractOwner = "";
let user = "";

const web3 = new Web3(Web3.givenProvider || "https://cloudflare-eth.com");


const Input = styled("input")({
  display: "none",
});

function NFTDocumentUpload(props) {
  const {
    formField,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = props.formData;

  const [tags, setTags] = useState([]);
  useEffect(() => {
    if (values?.documentTag) {
      setTags(values?.documentTag?.split(','));
    }
    GetNFTCat();
  }, []);

  const [fileObj, setFileObj] = useState(null);
  const [SelectedFile, SetSelectedFile] = useState(null);
  const [SelectedFileType, SetSelectedFileType] = useState("");
  const [SelectedFileDetails, SetSelectedFileDetails] = useState("");

  const [documentName, setDocumentName] = useState("");
  const [description, setDescription] = useState("");

  const setFileDetails = (file) => {
    SetSelectedFileType(file.type);
    SetSelectedFileDetails(
      `${file?.name} / ${parseFloat(file?.size / (1000 * 1000)).toFixed(2)} MB / ${file?.type}`
    );
    SetSelectedFile(URL.createObjectURL(file));
    setDocumentName(file.name);
    setFileObj(file);
    // setFieldValue("documentName", file.name);
    setFieldValue("documentMedia", file);
    props.onDocumentUploaded(file, documentName, description, tags.join(","));
  }

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setFileDetails(file);
    }
  };

  useEffect(() => {
    if (values?.documentName && values?.documentName?.length > 0 && !values?.documentName?.startsWith("COA: ")) {
      setFieldValue("documentName", "COA: " + values?.documentName);
    }
    if (values?.documentTag) {
      setTags(values?.documentTag?.split(','));
    }
    if (values?.publicDocumentMedia) {
      setFileDetails(values?.publicDocumentMedia);
    }
  }, []);

  const updateTags = (newTags) => {
    setTags(newTags);
    setFieldValue("documentTag", newTags.join(","));
  };

  const GetNFTCat = (value) => {
    var url = `${process.env.REACT_APP_API_BASE_URL}/documents/category-lookup/${localStorage.ShipperHash}`;
    if (value) {
      url += `?Name=${value}`;
    }
    var headers = {
      Authorization: `Bearer ${localStorage.id_token}`,
      authtype: "token",
    };
    axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          var catList = [];
          res.data &&
            res.data.map((cat, i) => {
              catList.push({ label: cat.Name, value: cat.Name });
            });
        }
      });
    // .finally(() => {
    //   props.setLoaderStatus(false);
    // });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} textAlign="center">
        <Card sx={{ overflow: "visible", height: "100%" }} elevation={0}>
          {SelectedFileType?.includes("video") ? (
            <video height="180" controls>
              <source src={SelectedFile} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <div
              style={{
                height: 235,
                width: 200,
                overflow: "hidden",
                margin: "0 auto",
                border: "1px dashed #314747",
                borderRadius: "6px",
                backgroundImage: `url(${SelectedFile})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'
              }}
            >
              {/* <img src={SelectedFile} width="90%" height={"auto"} style={{ margin: "10px auto" }} /> */}
            </div>
          )}
          <VuiBox mt={1}>
            <label htmlFor="contained-button-file">
              <Input
                id="contained-button-file"
                accept="image/*, video/*"
                onChange={onImageChange}
                type="file"
              />

              <VuiButton variant="contained" color="info" component="span">
                Choose
              </VuiButton>
              <VuiTypography
                mt={1}
                variant="h6"
                color={SelectedFileDetails ? "dark" : "error"}
                textAlign="center"
              >
                <span>{SelectedFileDetails ? SelectedFileDetails : errors.documentMedia}</span>
              </VuiTypography>
            </label>
          </VuiBox>
        </Card>
      </Grid>
      <Grid item xs={12} md={8}>
        <Card elevation={0}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <VuiBox p={1}>
                <VuiTypography variant="h6" color="dark" textAlign="left">
                  NFT/COA INFORMATION
                </VuiTypography>
              </VuiBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormField
                label={"NFT/COA Name"}
                name={formField.documentName.name}
                type={formField.documentName.type}
                value={values?.documentName}
                // disabled={values.orderChange}
                disabled={true}
                onChange={(e) => {
                  setDocumentName(e.target.value);
                  setFieldValue("documentName", e.target.value);
                }}
                placeholder={formField.documentName.placeholder}
                error={errors.documentName && touched.documentName}
                success={formField.documentName.length > 0 && !errors.documentName}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormField
                label={"NFT/COA Description"}
                name={formField.documentDescription.name}
                type={formField.documentDescription.type}
                value={values?.documentDescription}
                disabled={true}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setFieldValue("documentDescription", e.target.value);
                }}
                placeholder={formField.documentDescription.placeholder}
                error={errors.documentDescription && touched.documentDescription}
                success={formField.documentDescription.length > 0 && !errors.documentDescription}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormField
                label={"NFT/COA Category"}
                name={formField.documentCategory.name}
                type={formField.documentCategory.type}
                value={values?.documentCategory}
                disabled={true}
                placeholder={formField.documentCategory.placeholder}
                error={errors.documentCategory && touched.documentCategory}
                success={formField.documentCategory.length > 0 && !errors.documentCategory}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <VuiTypography style={{ fontWeight: 'bold' }}>DPS Token</VuiTypography>
              <VuiInput value={props?.dpsToken} disabled={true}></VuiInput>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormField
                label={"NFT/COA SKU ID"}
                name={formField.documentItemId.name}
                type={formField.documentItemId.type}
                value={values?.documentItemId}
                disabled={true}
                onChange={(e) => {
                  setFieldValue("documentItemId", e.target.value);
                }}
                placeholder={formField.documentItemId.placeholder}
                error={errors.documentItemId && touched.documentItemId}
                success={formField.documentItemId.length > 0 && !errors.documentItemId}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>

            <Grid item xs={12} md={12} mt={1.5}>
              <b>COA/Item Tags</b>
              <VuiTagInput disabled={true} placeholder="Tags" tags={tags} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default NFTDocumentUpload;
