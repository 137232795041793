import axios from "axios";
import VuiButton from "components/VuiButton";
import React from "react";
import DefaultItem from "examples/Items/DefaultItem";
import { IoDocumentSharp } from "react-icons/io5";
import CircularProgress from '@mui/material/CircularProgress';


class VerifyButton extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      loading: props?.loading ? props?.loading : false,
    };

  }

  componentWillReceiveProps(nextProps) {
    this.setState({ loading: nextProps?.loading });
  }

  handleClick = async (event) => {
    event.preventDefault();
    // Block native event handling.
    this.setState({ loading: true });

    // Call your backend to create the VerificationSession.
    const url = `${process.env.REACT_APP_API_BASE_URL}/stripe/create-session`;
    const headers = {
      Authorization: `Bearer ${localStorage.id_token}`,
      AuthType: "token",
    };
    const data = {
      ShipperHash: localStorage.ShipperHash,
      CallBackUrl: window.location.href,
      Mode: "document",
    };
    await axios
      .post(url, data, { headers: headers })
      .then((response) => {
        const session = response.data;
        this.setState({ loading: false });

        // When the user clicks on the button, redirect to the session URL.
        window.location.href = session.SessionUrl;
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <div>
        {
          this?.props?.type == 'link' ?

            <DefaultItem
              icon={<IoDocumentSharp size="18px" color="dark" />}
              title={"Verify Your Identity"}
              onClick={this.handleClick}
            /> :
            <VuiButton varient="outlined"
              color={this?.props?.color ? this?.props?.color : "secondary"}
              fullWidth
              disabled={this?.props?.disabled}
              style={{ marginTop: 10 }} onClick={this.handleClick}>
              {
                this.state.loading ? (
                  <CircularProgress size={16} color="error" />
                ) : (
                  this?.props?.title ? this?.props?.title : "Verify Your Identity"
                )
              }
            </VuiButton>
        }
      </div>
    );
  }
}

export default VerifyButton;
