

import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { GetDocument } from "redux/Actions/nft";
import Spin from "components/Spin";
import { useHistory } from "react-router-dom";
import { setLoaderStatus } from "redux/Actions/common";



import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import Pagination from '@mui/material/Pagination';
import ShowPdfPopup from "../track-wizard/Component/ShowPdfPopup";
import axios from 'axios';

import RefreshIcon from '@mui/icons-material/Refresh';

import Row from './row';


function OrderList(props) {
    const { GetDocument, DocumentList, loading } = props;
    const history = useHistory();
    const [TotalNoOfPage, SetTotalNoOfPage] = useState(0);
    const [CurrentPage, SetCurrentPage] = useState(1);

    useEffect(() => {
        GetDocument({});
    }, []);

    useEffect(() => {
        if (props?.DocumentList?.Records) {
            SetTotalNoOfPage(props?.DocumentList?.TotalPageCount);
            SetCurrentPage(props?.DocumentList?.CurrentPage);
        }
    }, [props?.DocumentList?.Records]);

    const onRowClick = (row, event) => {
        if (event == 'ePostage') {
            history.push("/send/e-postage", {
                step: 0,
                Id: row?.DocumentHash,
                PageCount: row?.PageCount,
                documentKey: row?.Path
            });
        }
        else if (event == 'print_to_mail') {
            history.push("/send/print-to-mail", {
                step: 0,
                Id: row?.DocumentHash,
                PageCount: row?.PageCount,
                documentKey: row?.Path
            });
        }
        else if (event == 'document_preview') {
            handleDocumentPreviewClick(row.Path);
        }
        else {

        }
    }

    const [documentPreviewDialogOpen, setDocumentPreviewDialogOpen] = useState(false);
    const [documentUrl, setDocumentUrl] = useState('');


    const handleDocumentPreviewClick = (documentKey) => {
        setDocumentUrl(null);
        props.setLoaderStatus(true);
        const headers = {
            Authorization: `Bearer ${localStorage.id_token}`,
            AuthType: "token",
        };
        const data = {
            Key: documentKey,
        };
        axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/downloads`, data, {
                headers: headers,
            })
            .then((res) => {
                setDocumentPreviewDialogOpen(true);
                setDocumentUrl(res.data.Url);
            }).finally(() => {
                props.setLoaderStatus(false);
            });
    };

    return (
        <Spin tip="Loading..." spinning={loading}>
            <ShowPdfPopup open={documentPreviewDialogOpen} url={documentUrl} SetOpen={setDocumentPreviewDialogOpen} />
            <Paper>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Timestamped Documents

                                <IconButton color="primary" aria-label="refresh page" component="label" onClick={() => {
                                    GetDocument({ "Page": CurrentPage });
                                }}>
                                    <RefreshIcon />
                                </IconButton>
                            </Typography>
                            {/* <div>
                                <Button
                                    style={{ paddingLeft: 10, paddingRight: 10 }}
                                    variant="contained"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="primary"
                                    size="small"
                                    endIcon={<KeyboardArrowDownIcon />}
                                >
                                    Filters
                                </Button>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                                    <MenuItem onClick={handleClose}>My account</MenuItem>
                                </Menu>
                            </div> */}

                            {/* <Button style={{ paddingLeft: 10, paddingRight: 10, marginLeft: 10 }}
                                variant="contained" color="primary"
                                size="small" endIcon={<FileDownloadIcon />}>
                                Export CSV
                            </Button> */}

                            <Button style={{ paddingLeft: 10, paddingRight: 10, marginLeft: 10 }}
                                variant="contained" color="primary"
                                size="small" endIcon={<AddIcon />} onClick={() => {
                                    history.push("/documents/e-notarize");
                                }}>
                                Create New
                            </Button>

                        </Toolbar>
                    </AppBar>
                </Box>
                <TableContainer component={"div"} sx={{ maxHeight: 440 }}>
                    <Table aria-label="collapsible table" stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Document</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell>Pages</TableCell>
                                <TableCell>Timestamped On</TableCell>
                                {/* <TableCell>Status</TableCell> */}
                                <TableCell>Document Info</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {DocumentList?.Records?.map((row, index) => (
                                <Row key={index} row={row} onRowClick={onRowClick} />
                            ))}
                        </TableBody>
                        {/* <TableFooter>
                            <TableRow>
                                <TableCell colSpan={7}>
                                    
                                </TableCell>
                            </TableRow>
                        </TableFooter> */}
                    </Table>
                </TableContainer>
                <Pagination
                    count={TotalNoOfPage}
                    defaultPage={1}
                    page={CurrentPage}
                    size="large"
                    onChange={(event, current) => {
                        SetCurrentPage(current);
                        GetDocument({ "Page": current });
                    }}
                    showFirstButton
                    showLastButton
                />
            </Paper>
        </Spin>
    );
}

export default connect(
    (state) => ({
        DocumentList: state.NftStore.documentRes,
        loading: state.CommonStore.loader,
    }),
    { GetDocument, setLoaderStatus }
)(OrderList);
