import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { connect } from "react-redux";
import { actPostTrack } from "redux/Actions/TrackingWizard";
import axios from "axios";
import VuiButton from "components/VuiButton";
import ShowPdfPopup from "./Component/ShowPdfPopup";
import Spin from "components/Spin";
import ViewReceipt from "./Component/ViewReciept";
import "./style.css";
import TransferNFT from "./Component/TransferNftPopUp";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import BlockChain from "assets/images/blockchain.png";
import { setLoaderStatus } from "redux/Actions/common";
import { Button, TextField } from "@mui/material";
import moment from "moment";
import VuiSelect from "components/VuiSelect";
import { RefreshProfile } from "redux/Actions/common";

import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useHistory } from "react-router-dom";
import Row from './row';
import RefreshIcon from '@mui/icons-material/Refresh';
import Pagination from '@mui/material/Pagination';


function TrackMailing(props) {
    const history = useHistory();
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const handleFilterModalOpen = () => setOpenFilterModal(true);
    const handleFilterModalClose = () => setOpenFilterModal(false);

    const { loading } = props;

    const [rowData, SetRowData] = useState([]);
    const [TotalNoOfRow, SetTotalNoOfRow] = useState(0);
    const [Limit, SetLimit] = useState(10);
    const [TotalNoOfPage, SetTotalNoOfPage] = useState(0);
    const [CurrentPage, SetCurrentPage] = useState(1);
    const [open, SetOpen] = useState(false);
    const [openViewReceipt, SetOpenViewReceipt] = useState(false);
    const [url, SetUrl] = useState("");
    const [loader, SetLoader] = useState(false);
    const [ReceiptData, SetReceiptData] = useState(null);
    const [Mode, SetMode] = useState({ value: "live", label: "Live" });
    const [Sender, SetSender] = useState("");
    const [Recipient, SetRecipient] = useState("");
    const [JobHash, SetJobHash] = useState("");
    const [ArticleNumber, SetArticleNumber] = useState("");
    const [LastStatus, SetLastStatus] = useState("");
    const [From, SetFrom] = useState("");
    const [To, SetTo] = useState("");
    const [openNFTTransferDialog, setOpenNFTTransferDialog] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [filterApplied, setAilterApplied] = useState(false);

    const [popupTitle, setPopupTitle] = useState("");

    const GetTrackingPayload = () => {
        var data = {
            ShipperHash: localStorage.ShipperHash,
            Limit: Limit,
            Page: CurrentPage,
        };
        setAilterApplied(false);
        let jobType = getJobTypeValue();

        if (jobType != null && jobType != '') {
            data["JobType"] = jobType
        }

        if (Mode) {
            data["Mode"] = "Live"; //Mode.value;
        }
        if (Sender) {
            data["FromName"] = Sender;
            setAilterApplied(true);
        }
        if (Recipient) {
            data["ToName"] = Recipient;
            setAilterApplied(true);
        }
        if (JobHash) {
            data["JobHash"] = JobHash;
            setAilterApplied(true);
        }
        if (ArticleNumber) {
            data["ArticleNumber"] = ArticleNumber;
            setAilterApplied(true);
        }

        if (LastStatus) {
            data["LastStatus"] = LastStatus?.value;
            setAilterApplied(true);
        }
        if (From && To) {
            data["DateRange"] = `${From}, ${To}`;
            setAilterApplied(true);
        }
        return data;
    };

    const getJobTypeValue = () => {
        let jobType = null;
        if (window.location.pathname == '/track/e-postage') {
            jobType = 'ePostage';
        }
        else if (window.location.pathname == '/track/nft') {
            jobType = 'NFT';
        }
        else if (window.location.pathname == '/track/print-to-mail') {
            jobType = 'Print2Mail';
        }
        return jobType;
    }

    useEffect(() => {
        var data = GetTrackingPayload();
        actTrackingApi(data);
    }, [Limit, CurrentPage]);

    function actTrackingApi(data) {
        props.actPostTrack(data);
    }

    useEffect(() => {
        if (props?.trackingRes?.Records) {
            SetRowData(props?.trackingRes?.Records);
            SetTotalNoOfRow(props?.trackingRes?.TotalRecords);
            SetTotalNoOfPage(props?.trackingRes?.TotalPageCount);
            SetCurrentPage(props?.trackingRes?.CurrentPage);
        }
    }, [props?.trackingRes?.Records]);



    const handleDocumentClick = (key) => {
        SetLoader(true);
        const headers = {
            Authorization: `Bearer ${localStorage.id_token}`,
            AuthType: "token",
        };
        const data = {
            Key: key,
        };
        axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/downloads`, data, {
                headers: headers,
            })
            .then((res) => {
                SetOpen(true);
                SetUrl(res.data.Url);
                SetLoader(false);
            });
    };

    const handleDocumentZipClick = (JobHash) => {
        SetLoader(true);
        const headers = {
            Authorization: `Bearer ${localStorage.id_token}`,
            AuthType: "token",
        };
        const data = {
            DocType: "ZipPack",
            JobHash: JobHash,
        };
        axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/downloads`, data, {
                headers: headers,
            })
            .then((res) => {
                SetLoader(false);
                window.open(res.data.Url, "_blank");
            });
    };

    const handleReceiptClick = (data) => {
        SetReceiptData(data);
        SetOpenViewReceipt(true);
    };

    const handleReceiptClose = () => {
        SetOpenViewReceipt(false);
    };



    const HandleCancel = (JobHash) => {
        if (confirm("You Want to Cancel the Mailing Record?") == true) {
            SetLoader(true);
            const headers = {
                Authorization: `Bearer ${localStorage.id_token}`,
                AuthType: "token",
            };
            axios
                .get(`${process.env.REACT_APP_API_BASE_URL}/jobs/cancel/${JobHash}`, {
                    headers: headers,
                })
                .then((res) => {
                    SetLoader(true);
                    var data = {
                        ShipperHash: localStorage.ShipperHash,
                    };
                    props.actPostTrack(data);
                    SetLoader(false);
                    alert("Successfully Cancelled!");
                })
                .catch((error) => {
                    SetLoader(false);
                    alert(error?.response?.data?.error);
                })
                .finally(() => {
                    props?.RefreshProfile();
                });
        }
    };



    const PostTrack = () => {
        var data = GetTrackingPayload();

        props.actPostTrack(data);
    };

    const setLoading = (status) => {
        props.setLoaderStatus(status);
    };

    const HandleSenderChange = (e) => {
        SetSender(e.target.value);
        // var data = GetTrackingPayload();
        // if (e.target.value) data["FromName"] = e.target.value;
        // actTrackingApi(data);
    };

    const HandleRecipientChange = (e) => {
        SetRecipient(e.target.value);
        // var data = GetTrackingPayload();
        // if (e.target.value) data["ToName"] = e.target.value;
        // actTrackingApi(data);
    };

    const HandleJobHashChange = (e) => {
        SetJobHash(e.target.value);
    };

    const HandleArticleNumberChange = (e) => {
        SetArticleNumber(e.target.value);
    };

    const HandleLastStatusChange = (value) => {
        SetLastStatus(value);
    };

    const HandleFromChange = (e) => {
        var date = "";
        if (e.target.value) date = moment(e.target.value).format("MM/DD/YYYY");
        SetFrom(date);
    };

    const HandleToChange = (e) => {
        var date = "";
        if (e.target.value) date = moment(e.target.value).format("MM/DD/YYYY");
        SetTo(date);
    };

    const HandleFilterClick = () => {
        handleFilterModalClose();
        var data = GetTrackingPayload();
        actTrackingApi(data);
    };

    const HandleResetClick = () => {
        setAilterApplied(false);
        handleFilterModalClose();

        SetMode({ value: "live", label: "Live" });
        SetSender("");
        SetRecipient("");
        SetJobHash("");
        SetArticleNumber("");
        SetLastStatus("");
        SetFrom("");
        SetTo("");
        var data = {
            ShipperHash: localStorage.ShipperHash,
            Limit: Limit,
            Page: CurrentPage,
            Mode: "live"
        };
        // actTrackingApi(data);
    };

    const onRowClick = (row, event) => {
        setCurrentRow(row);
        if (event == 'view_label') {
            handleDocumentClick(row.PostageLabelPath);
            setPopupTitle('Label Preview');
        }
        else if (event == 'view_receipt') {
            handleReceiptClick(row);
            setPopupTitle('Receipt Preview');
        }
        else if (event == 'transfer_nft') {
            setOpenNFTTransferDialog(true);
        }
        else if (event == 'cancel') {
            HandleCancel(row.JobHash);
        }
        else if (event == 'view_certified_document') {
            handleDocumentClick(row.DocumentPath);
            setPopupTitle('Certified Document Preview');
        }
        else if (event == 'download_all_documents') {
            handleDocumentZipClick(row.JobHash);
        }
        else if (event == 'certificate_of_mailing') {
            handleDocumentClick(row?.ComInfo?.S3Path);
            setPopupTitle('Certificate of Mailing Preview');
        }
        else if (event == 'certificate_of_delivery') {
            handleDocumentClick(row?.CodInfo?.S3Path);
            setPopupTitle('Certificate of Delivery Preview');
        }
        else if (event == 'verify_package') {
            history.push('/verified/blockchain?vf=true', { Id: row.DocumentHash })
        }
    }

    return (
        <div>
            <Paper>
                <Dialog open={openFilterModal} fullScreen={false} maxWidth={"lg"} onClose={handleFilterModalClose}>
                    <DialogTitle>Filters</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} mb={1} mt={4}>

                            {/* <Grid item md={4} style={{ paddingTop: 0 }}>
                                <Typography variant="caption">Mode</Typography>
                                <VuiSelect
                                    name="mode"
                                    placeholder="Mode"
                                    variant="outlined"
                                    value={Mode}
                                    size={"large"}
                                    onChange={(e) => HandleChangeMode(e)}
                                    options={[
                                        { value: "live", label: "Live" },
                                        { value: "staging", label: "Staging" },
                                    ]}
                                />
                            </Grid> */}
                            <Grid item md={4} style={{ paddingTop: 0 }}>
                                <Typography variant="caption">Sender</Typography>
                                <TextField
                                    name="sender"
                                    value={Sender}
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    placeholder="Sender Name"
                                    onChange={(e) => HandleSenderChange(e)}
                                />
                            </Grid>
                            <Grid item md={4} style={{ paddingTop: 0 }}>
                                <Typography variant="caption">Recipient</Typography>
                                <TextField
                                    name="Recipient"
                                    value={Recipient}
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                    placeholder="Recipient Name"
                                    onChange={(e) => HandleRecipientChange(e)}
                                />
                            </Grid>

                            <Grid item md={4}>
                                <Typography variant="caption">Job Hash</Typography>
                                <TextField
                                    name="JobHash"
                                    value={JobHash}
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                    placeholder="Job Hash"
                                    onChange={(e) => HandleJobHashChange(e)}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant="caption">Article Number</Typography>
                                <TextField
                                    name="ArticleNumber"
                                    value={ArticleNumber}
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                    placeholder="Article Number"
                                    onChange={(e) => HandleArticleNumberChange(e)}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant="caption">From Date</Typography>
                                <TextField
                                    name="from"
                                    defaultValue={From ? moment(From).format("YYYY/MM/DD") : ""}
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                    placeholder="From Date"
                                    type="date"
                                    onChange={HandleFromChange}
                                    sx={{ width: 220 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant="caption">To Date</Typography>
                                <TextField
                                    name="To"
                                    defaultValue={To ? moment(To).format("YYYY/MM/DD") : ""}
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                    placeholder="To Date"
                                    type="date"
                                    onChange={HandleToChange}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant="caption">Status</Typography>
                                <VuiSelect
                                    name="LastStatus"
                                    placeholder="Status"
                                    variant="outlined"
                                    value={LastStatus}
                                    size={"large"}
                                    style={{ width: "100%" }}
                                    onChange={(e) => HandleLastStatusChange(e)}
                                    menuPlacement={"top"}
                                    options={[
                                        { value: "Mailed", label: "Mailed" },
                                        { value: "Processing", label: "Processing" },
                                        { value: "Cancelled", label: "Cancelled" },
                                        { value: "Delivered", label: "Delivered" },
                                        { value: "Ordered", label: "Ordered" },
                                        { value: "Failed", label: "Failed" },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleFilterModalClose} style={{ float: 'left' }}>Cancel</Button>

                        <Button onClick={HandleResetClick}>Clear Filter</Button>
                        <Button onClick={HandleFilterClick}>Apply Filter</Button>
                    </DialogActions>
                </Dialog>

                {
                    currentRow && openNFTTransferDialog ? (<TransferNFT
                        nftAddress={currentRow?.NftWalletAddress}
                        tokenId={currentRow?.TokenId}
                        documentHash={currentRow?.DocumentHash}
                        actPostTrack={PostTrack}
                        setLoading={setLoading}
                        loading={loading}
                        open={openNFTTransferDialog}
                    />) : null
                }


                <ViewReceipt
                    open={openViewReceipt}
                    data={ReceiptData}
                    handleReceiptClose={handleReceiptClose}
                />

                <ShowPdfPopup title={popupTitle} open={open} url={url} SetOpen={SetOpen} />

                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Track Mailing
                                <IconButton color="primary" aria-label="refresh page" component="label" onClick={HandleFilterClick}>
                                    <RefreshIcon />
                                </IconButton>
                            </Typography>

                            <IconButton
                                size="large"
                                edge="start"
                                color={filterApplied ? "primary" : "inherit"}
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={handleFilterModalOpen}
                            >
                                <FilterAltOutlinedIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Box>
                <Spin tip="loading..." spinning={props.loading || loader}>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Topic Details</TableCell>
                                    {/* <TableCell>Sender</TableCell> */}
                                    <TableCell>Recepient</TableCell>
                                    <TableCell>Postage</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Ordered On</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowData.map((row, index) => (
                                    <Row key={index} row={row} index={index} onRowClick={onRowClick} />
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={8}>
                                        <Pagination
                                            count={TotalNoOfPage}
                                            defaultPage={1}
                                            page={CurrentPage}
                                            size="large"
                                            onChange={(event, Current) => {
                                                SetCurrentPage(Current);
                                            }}
                                            showFirstButton
                                            showLastButton
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Spin>
            </Paper>
            <br />
            <br />
        </div>
    );
}

export default connect(
    (state) => ({
        trackingRes: state.TrackingWizardStore.trackingRes,
        TrackingSuccess: state.TrackingWizardStore.TrackingSuccess,
        TrackingError: state.TrackingWizardStore.TrackingError,
        loading: state.CommonStore.loader,
    }),
    { actPostTrack, setLoaderStatus, RefreshProfile }
)(TrackMailing);
