

import { useEffect, useState } from "react";

// @mui material components

import { connect } from "react-redux";
import { GetNFT } from "redux/Actions/nft";
import Spin from "components/Spin";
import { useHistory } from "react-router-dom";
import axios from "axios";




import Card from '@mui/material/Card';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import Row from './row';

import Grid from "@mui/material/Grid";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import VuiSelect from "components/VuiSelect";
import RefreshIcon from '@mui/icons-material/Refresh';

import dps_logo from "../../../../assets/images/dps-logo.png";
import polygon_logo from "../../../../assets/images/logos/polygon.png"


function NFTListV2(props) {
    const history = useHistory();
    const { GetNFT, NFTList, loading } = props;
    const [menu, setMenu] = useState(null);
    const [nftList, setNftList] = useState([]);
    const [ItemId, setItemId] = useState([]);
    // const [Category, setCategory] = useState([]);
    const [Name, setDocumentName] = useState([]);
    const [Description, setDescription] = useState([]);
    const [Tags, setTags] = useState([]);
    const [CatOption, setCatOption] = useState([]);
    const [Category, setCategory] = useState("");

    const [filterApplied, setFilterApplied] = useState(false);
    const [TotalNoOfPage, SetTotalNoOfPage] = useState(0);
    const [CurrentPage, SetCurrentPage] = useState(1);

    const [openFilterModal, setOpenFilterModal] = useState(false);
    const handleFilterModalOpen = () => setOpenFilterModal(true);
    const handleFilterModalClose = () => setOpenFilterModal(false);

    useEffect(() => {
        CallGetNFT(CurrentPage);
        GetNFTCat();
    }, []);

    const CallGetNFT = (pageNumber) => {
        var params = {
            ItemId: ItemId,
            Category: Category,
            Name: Name,
            Description: Description,
            Tags: Tags,
            Page: pageNumber
        };
        if (ItemId || Category || Name || Description || Tags) {
            setFilterApplied(true);
        }
        else {
            setFilterApplied(false);
        }
        GetNFT(params);
    };

    const GetNFTCat = (value) => {
        var url = `${process.env.REACT_APP_API_BASE_URL}/documents/category-lookup/${localStorage.ShipperHash}`;
        if (value) {
            url += `?Name=${value}`;
        }
        var headers = {
            Authorization: `Bearer ${localStorage.id_token}`,
            authtype: "token",
        };
        axios
            .get(url, {
                headers: headers,
            })
            .then((res) => {
                if (res.status === 200) {
                    var catList = [];
                    res.data &&
                        res.data.map((cat, i) => {
                            catList.push({ label: cat.Name, value: cat.Name });
                        });
                    setCatOption(catList);
                }
            });
        // .finally(() => {
        //   props.setLoaderStatus(false);
        // });
    };

    // useEffect(() => {
    //     CallGetNFT();
    // }, [CurrentPage])

    useEffect(() => {
        if (NFTList) {
            // var data = _.filter(NFTList, (list) => list.IsMinted);
            setNftList(NFTList);
            SetTotalNoOfPage(NFTList?.TotalPageCount);
            SetCurrentPage(NFTList?.CurrentPage);
        }
    }, [NFTList]);

    const onRowClick = (row, event) => {
        if (event == 'ePostage') {
            history.push("/send/e-postage", { step: 0, Id: row?.PublicDocumentHash, IsNFT: true });
        }
        else if (event == 'copy') {
            navigator.clipboard.writeText(row);
        }
        else if (event == 'transfer') {

        }
        else if (event == 'mint_coa') {
            history.push('/nft/create', { step: 2, ...row });
        }
        else if (event == 'verify') {
            history.push('/verified/blockchain?vf=true', { Id: row.PublicDocumentHash })
        }
        else if (event == 'verify_token') {
            history.push('/verified/blockchain?vf=true', { Id: row })
        }

    };

    const HandleFilterClick = () => {
        handleFilterModalClose();
        CallGetNFT(CurrentPage);
    };

    const HandleItemIdChange = (e) => {
        setItemId(e.target.value);
    };

    const HandleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const HandleTagsChange = (e) => {
        setTags(e.target.value);
    };

    const HandleResetClick = () => {
        handleFilterModalClose();
        setItemId("");
        setCategory("");
        setDescription("");
        setTags("");
        var params = {
            ItemId: "",
            Category: "",
            Description: "",
            Tags: "",
        };
        GetNFT(params);
    };

    return (

        props?.profile?.Permissions == null || props?.profile?.Permissions?.NftAccess == false ? (
            <Card>
                <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    The create NFT & Send NFT function is only available to merchants after they submit an application to prove they are a licensed business and are the manufactures or creators of the items to be shipped
                    <br />
                    <iframe frameBorder={0} style={{ height: 1500, width: '99%', border: 'none' }} src='https://forms.zohopublic.com/dpsportal8/form/NFTCOAREQUEST/formperma/T2LOjl812Ib81penBF3lVHEG1ZCxPkf_BRndYgzef3A'></iframe>
                </div>
            </Card>
        ) : (
            <Spin tip="Loading..." spinning={loading}>
                <Dialog open={openFilterModal} fullScreen={false} maxWidth={"lg"} onClose={handleFilterModalClose}>
                    <DialogTitle>Filters</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} mb={2} mt={2} alignItems="end">
                            <Grid item md={3} style={{ paddingTop: 0 }}>
                                <Typography variant="caption">Item Id</Typography>
                                <TextField
                                    name="itemId"
                                    value={ItemId}
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                    placeholder="Item Id"
                                    onChange={(e) => HandleItemIdChange(e)}
                                />
                            </Grid>

                            <Grid item md={3} style={{ paddingTop: 0 }}>
                                <Typography variant="caption">Category</Typography>
                                <VuiSelect
                                    placeholder="Category"
                                    options={CatOption}
                                    value={CatOption.find((obj) => obj.value === Category)}
                                    onChange={({ value }) => {
                                        setCategory(value);
                                    }}
                                    isSearchable
                                    onInputChange={GetNFTCat}
                                />
                            </Grid>
                            <Grid item md={3} style={{ paddingTop: 0 }}>
                                <Typography variant="caption">Description</Typography>
                                <TextField
                                    name="DescriptionTag"
                                    value={Description}
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                    placeholder="Description"
                                    onChange={(e) => HandleDescriptionChange(e)}
                                />
                            </Grid>
                            <Grid item md={3} style={{ paddingTop: 0 }}>
                                <Typography variant="caption">Tags</Typography>
                                <TextField
                                    name="Tags"
                                    value={Tags}
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                    placeholder="Tags"
                                    onChange={(e) => HandleTagsChange(e)}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleFilterModalClose} style={{ float: 'left' }}>Cancel</Button>

                        <Button onClick={HandleResetClick}>Clear Filter</Button>
                        <Button onClick={HandleFilterClick}>Apply Filter</Button>
                    </DialogActions>
                </Dialog>

                <Paper style={{ marginBottom: 65 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <AppBar position="static">
                            <Toolbar>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                    NFT List
                                    <IconButton color="primary" aria-label="refresh page" component="label" onClick={() => {
                                        CallGetNFT(CurrentPage);
                                    }}>
                                        <RefreshIcon />
                                    </IconButton>

                                </Typography>

                                <Button style={{ paddingLeft: 10, paddingRight: 10, marginLeft: 10 }}
                                    variant="contained" color="primary"
                                    size="small" endIcon={<AddIcon />} onClick={() => {
                                        history.push("/nft/create");
                                    }}>
                                    Create New
                                </Button>
                                &nbsp;&nbsp;
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color={"inherit"}
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                    onClick={handleFilterModalOpen}
                                >
                                    <FilterAltOutlinedIcon />
                                </IconButton>

                            </Toolbar>
                        </AppBar>
                    </Box>
                    <TableContainer component={'div'} sx={{ maxHeight: 440 }}>
                        <Table aria-label="collapsible table" stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Item Name</TableCell>
                                    {/* <TableCell>
                                    <img src={dps_logo} alt="dps-logo" style={{ width: 30 }} />
                                    Token
                                </TableCell> */}
                                    <TableCell>COA Name</TableCell>
                                    <TableCell align="center" style={{ maxWidth: 85 }}>
                                        <img src={polygon_logo} alt="dps-logo" style={{ width: 30 }} />
                                        NFT ID
                                    </TableCell>
                                    <TableCell align="center" style={{ maxWidth: 100 }}>USPS Tracking</TableCell>
                                    <TableCell>Last Updated</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {nftList?.Records?.map((row, index) => (
                                    <Row key={index} row={row} onRowClick={onRowClick} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
                        count={TotalNoOfPage}
                        defaultPage={1}
                        page={CurrentPage}
                        size="large"
                        onChange={(event, current) => {
                            SetCurrentPage(current);
                            CallGetNFT(current);
                        }}
                        showFirstButton
                        showLastButton
                    />
                </Paper>
            </Spin>
        )
    );
}

export default connect(
    (state) => ({ NFTList: state.NftStore.NFTRes, loading: state.CommonStore.loader, profile: state.CommonStore.Profile, }),
    { GetNFT }
)(NFTListV2);
