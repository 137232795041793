import axios from "axios";

export const PostTrackApi = (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/trackings`;
  const headers = {
    Authorization: `Bearer ${localStorage.id_token}`,
    AuthType: "token",
  };

  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
