

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Table from "examples/Tables/Table";
import { Field } from "formik";
import "./style.css";
import VuiButton from "components/VuiButton";
import { connect } from "react-redux";
import {
  ActPostTopic,
  ActPostJob,
  PostPackageCheckOut,
  setJobPostSuccess,
  PostRecipient,
  setActPostPackageCheckOutSuccess,
  setActRecipientSuccess,
  setActTopicSuccess,
  setActSeletedTopicData,
} from "redux/Actions/wizard";
import Breadcrumbs from "examples/Breadcrumbs";
import AvailableCreditsPopUp from "./AvailableCreditsPopUp";

function specialService(data) {
  let total = 0;
  _.forEach(data, (service) => {
    total += service?.Price;
  });
  return total;
}
var antd_default_date = new Date();
antd_default_date.setDate(antd_default_date.getDate() + 5);


function Summary(props) {
  const { formField, values, errors } = props?.formData;
  const { shipDate } = formField;
  const {
    packageType,
    SelectedPackagePricingData,
    CertificateOfMailing,
    CertificateOfDelivery,
    RecordManagement,
    SelectedTopic,
  } = props;

  const [rows, setRows] = useState([]);

  useEffect(() => {
    var rows = [];
    if (!CertificateOfMailing && !CertificateOfDelivery) {
      rows = [
        {
          // Item: `${SelectedPackagePricingData?.ServiceCode} (${values.packageType})`,
          Item: SelectedPackagePricingData.Title,
          Amount: `$ ${parseFloat(parseFloat(SelectedPackagePricingData?.Rate) +
            specialService(SelectedPackagePricingData?.SpecialServices)).toFixed(2)
            }`,
        },
        {
          Item: `${RecordManagement?.Name}`,
          Amount: `$ ${parseFloat(RecordManagement?.Price).toFixed(2)}`,
        },
        {
          Item: "Total",
          Amount: `$ ${parseFloat(
            parseFloat(SelectedPackagePricingData?.Rate) +
            specialService(SelectedPackagePricingData?.SpecialServices) +
            parseFloat(RecordManagement?.Price)
          ).toFixed(2)}`,
        },
      ];
    } else if (!CertificateOfMailing) {
      rows = [
        {
          // Item: `${SelectedPackagePricingData?.ServiceCode} (${values.packageType})`,
          Item: SelectedPackagePricingData.Title,
          Amount: `$ ${parseFloat(parseFloat(SelectedPackagePricingData?.Rate) +
            specialService(SelectedPackagePricingData?.SpecialServices)).toFixed(2)
            }`,
        },
        {
          Item: `${CertificateOfDelivery?.Name}`,
          Amount: `$ ${parseFloat(CertificateOfDelivery?.Price).toFixed(2)}`,
        },
        {
          Item: `${RecordManagement?.Name}`,
          Amount: `$ ${parseFloat(RecordManagement?.Price).toFixed(2)}`,
        },
        {
          Item: "Total",
          Amount: `$ ${parseFloat(
            parseFloat(SelectedPackagePricingData?.Rate) +
            specialService(SelectedPackagePricingData?.SpecialServices) +
            parseFloat(CertificateOfDelivery?.Price) +
            parseFloat(RecordManagement?.Price)
          ).toFixed(2)}`,
        },
      ];
    } else if (!CertificateOfDelivery) {
      rows = [];
      let total = parseFloat(SelectedPackagePricingData?.Rate) +
        specialService(SelectedPackagePricingData?.SpecialServices) +
        parseFloat(RecordManagement?.Price);

      rows.push(
        {
          Item: SelectedPackagePricingData.Title,
          Amount: `$ ${parseFloat(parseFloat(SelectedPackagePricingData?.Rate) +
            specialService(SelectedPackagePricingData?.SpecialServices)).toFixed(2)
            }`,
        }
      );

      if (CertificateOfMailing?.Code != 'No') {
        rows.push(
          {
            Item: `${CertificateOfMailing?.Name}`,
            Amount: `$ ${parseFloat(CertificateOfMailing?.Price).toFixed(2)}`,
          }
        );
        total = total + parseFloat(CertificateOfMailing?.Price)
      }

      rows.push(
        {
          Item: `${RecordManagement?.Name}`,
          Amount: `$ ${parseFloat(RecordManagement?.Price).toFixed(2)}`,
        }
      )

      rows.push(
        {
          Item: "Total",
          Amount: `$ ${parseFloat(total).toFixed(2)}`,
        }
      )

    } else {
      rows = [];
      let total = parseFloat(SelectedPackagePricingData?.Rate) +
        specialService(SelectedPackagePricingData?.SpecialServices) +
        parseFloat(CertificateOfDelivery?.Price) +
        RecordManagement?.Price;


      rows.push({
        Item: SelectedPackagePricingData.Title,
        Amount: `$ ${parseFloat(parseFloat(SelectedPackagePricingData?.Rate) +
          specialService(SelectedPackagePricingData?.SpecialServices)).toFixed(2)
          }`,
      });
      if (CertificateOfMailing?.Code != 'No') {
        rows.push({
          Item: `${CertificateOfMailing?.Name}`,
          Amount: `$ ${parseFloat(CertificateOfMailing?.Price).toFixed(2)}`,
        });
        total = total + parseFloat(CertificateOfMailing?.Price);
      }

      rows.push({
        Item: `${CertificateOfDelivery?.Name}`,
        Amount: `$ ${parseFloat(CertificateOfDelivery?.Price).toFixed(2)}`,
      });
      rows.push({
        Item: `${RecordManagement?.Name}`,
        Amount: `$ ${parseFloat(RecordManagement?.Price).toFixed(2)}`,
      });
      rows.push({
        Item: "Total",
        Amount: `$ ${parseFloat(total).toFixed(2)}`,
      });
    }
    setRows(rows);
  }, [SelectedPackagePricingData, CertificateOfMailing, CertificateOfDelivery, RecordManagement]);

  return (
    <VuiBox>
      {/* <Breadcrumbs
        icon="home"
        title="Summary"
        route={[
          "dashboard",
          "General",
          "Sender Info",
          "Recipient Info",
          "Package Type",
          "Postage Type",
          "Certificates",
          "Summary",
        ]}
      /> */}
      <VuiBox width="100%" textAlign="left" mx="auto" mb={4}>
        <VuiBox>
          <VuiTypography variant="lg" fontWeight="bold" color="dark">
            Case Name: {values?.caseName}
          </VuiTypography>
          <br />
          <VuiTypography variant="lg" fontWeight="bold" color="dark">
            Mail Name: {values?.mailingName}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
      <VuiBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <VuiBox>
              <VuiTypography variant="lg" fontWeight="bold" color="dark">
                From
              </VuiTypography>
            </VuiBox>
            <VuiBox>
              <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                {values?.senderName}
              </VuiTypography>
            </VuiBox>

            <VuiBox>
              <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                {values?.address1}
              </VuiTypography>
            </VuiBox>
            <VuiBox>
              <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                {values.address2}
              </VuiTypography>
            </VuiBox>
            <VuiBox>
              <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                {(values?.city, values?.state, values?.zip)}
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={6} md={6}>
            <VuiBox>
              <VuiTypography variant="lg" fontWeight="bold" color="dark">
                To
              </VuiTypography>
            </VuiBox>
            <VuiBox>
              <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                {values?.recipientName}
              </VuiTypography>
            </VuiBox>

            <VuiBox>
              <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                {values?.recAddress1}
              </VuiTypography>
            </VuiBox>
            <VuiBox>
              <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                {values.recAddress2}
              </VuiTypography>
            </VuiBox>
            <VuiBox>
              <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                {(values?.recCity, values?.recState, values?.recZip)}
              </VuiTypography>
            </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>
      {values?.IsHazardous ? (
        <>
          <VuiBox mb={1.5}>
            <span>
              <b>Package content:</b> {values?.PackageContent}
            </span>
          </VuiBox>
        </>
      ) : (
        ""
      )}
      <VuiBox mt={2}>
        <Table
          columns={[
            { name: "Item", align: "left" },
            { name: "Amount", align: "right" },
          ]}
          rows={rows}
        />
      </VuiBox>

      <VuiBox mt={1.5}>
        <Grid container justifyContent="space-between">
          <Grid>
            <VuiTypography component="label" fontWeight="bold" style={{ fontSize: 13, paddingLeft: 8 }}>
              Ship Date: {values?.shipDate}
            </VuiTypography>
          </Grid>
          <Grid>
            {
              props?.Profile?.AvailableBalance < 5 ? <AvailableCreditsPopUp /> : null
            }

          </Grid>
        </Grid>
      </VuiBox>
    </VuiBox>
  );
}

export default connect(
  (state) => ({
    Profile: state.CommonStore.Profile,
    senderInfoData: state.WizardStore.sender_info,
    RecipientInfo: state.WizardStore.recipient_info,
    packageType: state.WizardStore.packageType,
    packageData: state.WizardStore.packageData,
    SelectedPackagePricingData: state.WizardStore.SelectedPackagePricingData,
    CertificateOfMailing: state.WizardStore.CertificateOfMailing,
    CertificateOfDelivery: state.WizardStore.CertificateOfDelivery,
    RecordManagement: state.WizardStore.RecordManagement,
    SelectedTopic: state.WizardStore.SelectedTopic,
    TopicRes: state.WizardStore.TopicRes,
    JobResponse: state.WizardStore.JobResponse,
    PackageCheckOutRes: state.WizardStore.PackageCheckOutRes,
    RecipientData: state.WizardStore.RecipientData,
  }),
  {
    ActPostTopic,
    ActPostJob,
    PostPackageCheckOut,
    setJobPostSuccess,
    PostRecipient,
    setActTopicSuccess,
    setActRecipientSuccess,
    setActPostPackageCheckOutSuccess,
    setActSeletedTopicData,
  }
)(Summary);
