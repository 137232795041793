import { useEffect, useState, useRef } from "react";

// @mui material components
import Grid from "@mui/material/Grid";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import Card from "@mui/material/Card";
import { connect } from "react-redux";
import { setLoaderStatus } from "redux/Actions/common";
import VuiTagInput from "components/VuiTagInput";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { styled } from "@mui/material/styles";
import Spin from "components/Spin";
import UploadImg from "assets/images/send-wizard/upload-icon.png";
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import HelpIcon from '@mui/icons-material/Help';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';


const Input = styled("input")({
  display: "none",
});

function SelectDocument(props) {
  const [defaultImage, setDefaultImage] = useState(false);
  const { loading } = props;
  const {
    formField,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = props.formData;
  const [SelectedFile, SetSelectedFile] = useState(null);
  const [SelectedFileDetails, SetSelectedFileDetails] = useState("");

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      // SetSelectedFileType(file.type);
      SetSelectedFileDetails(
        `${file?.name} / ${parseFloat(file?.size / (1000 * 1000)).toFixed(2)} MB / ${file?.type}`
      );
      SetSelectedFile(URL.createObjectURL(file));
      setDocumentName(file.name);
      setFieldValue("documentName", file.name);
      setFieldValue("documentMedia", file);
      setFieldValue("documentFileName", file.name);
      setDefaultImage(true);
    }
  };

  useEffect(() => {
    getCategories();

    if (values != null && values?.documentMedia != null && values?.documentMedia != "") {
      const file = values?.documentMedia;
      // SetSelectedFileType(file.type);
      SetSelectedFileDetails(
        `${file?.name} / ${parseFloat(file?.size / (1000 * 1000)).toFixed(2)} MB / ${file?.type}`
      );
      SetSelectedFile(URL.createObjectURL(file));
      setDocumentName(file.name);
      setDefaultImage(true);
      if (values?.documentTag != null && values?.documentTag != '') {
        setTags(values?.documentTag?.split(','));
      }
    }
  }, [])

  const [documentName, setDocumentName] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const updateTags = (newTags) => {
    setTags(newTags);
    setFieldValue("documentTag", newTags.join(","));
  };

  const [categories, setCategories] = useState([]);
  const [documentCategory, setDocumentCategory] = useState(null);


  const getCategories = (value) => {
    var url = `${process.env.REACT_APP_API_BASE_URL}/documents/category-lookup/${localStorage.ShipperHash}`;
    if (value) {
      url += `?Name=${value}`;
    }
    var headers = {
      Authorization: `Bearer ${localStorage.id_token}`,
      authtype: "token",
    };
    axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          var catList = [];
          res.data &&
            res.data.map((cat, i) => {
              catList.push({ label: cat.Name, value: cat.Name });
            });
          setCategories(catList);
        }
      });
  }
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogToggle = () => {
    setDialogOpen(prevCheck => !prevCheck);
  }

  return (
    <Spin tip="Loading..." spinning={loading}>

      <Dialog
        open={dialogOpen}
        disableEscapeKeyDown={true}
        onClose={handleDialogToggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Document Upload Guidelines
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ color: '#000000' }}>
            <h5>Print To Mail Online</h5>
            For all postage types standard letter page size is expected
            <ul>
              <li>Page width should be between 8 to 8.5 inches</li>
              <li>Page Height should be between 9 to 11 inches</li>
              <li>Images in document can not be higher than 300 DPI</li>
              <li>The maximum file size for all postage types is 100MB</li>
            </ul>
            <h5>ePostage Label</h5>
            If documents do not meet requirements for mailing online then only the ePostage option will appear.
            You will then print your documents and self-mail the documents.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogToggle}>
            Close
          </Button>
        </DialogActions>
      </Dialog>


      <Grid container spacing={2}>
        <Grid item xs={12} md={4} textAlign="center">
          <Card sx={{ overflow: "visible", height: "100%" }} elevation={0}>

            {!defaultImage ? (
              <img src={UploadImg} style={{ maxWidth: "100%", width: "160px", height: '229px', margin: '0px auto' }} />
            ) : (
              <iframe src={SelectedFile} width="100%" height="100%" style={{ overflow: 'auto' }} />
            )}
            {/* </div> */}
            <VuiBox mt={1}>
              <label htmlFor="contained_button_file">
                <Input
                  // ref={inputFile}
                  id="contained_button_file"
                  accept=".pdf"
                  onChange={onImageChange}
                  type="file"
                />
                <VuiButton variant="contained" color="info" component="span">
                  Choose
                </VuiButton>
                <VuiTypography
                  mt={1}
                  variant="h6"
                  color={SelectedFileDetails ? "dark" : "error"}
                  textAlign="center"
                >
                  {SelectedFileDetails ? SelectedFileDetails : errors.documentMedia}
                </VuiTypography>
              </label>
            </VuiBox>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card elevation={0}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <VuiBox p={1}>
                  <VuiTypography variant="h6" color="dark" textAlign="left">
                    DOCUMENT INFORMATION
                    <IconButton onClick={handleDialogToggle}>
                      <HelpIcon />
                    </IconButton>
                  </VuiTypography>
                </VuiBox>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormField
                  label={formField?.documentName?.label}
                  name={formField?.documentName?.name}
                  type={formField?.documentName?.type}
                  value={values?.documentName}
                  disabled={values?.orderChange}
                  onChange={(e) => {
                    setDocumentName(e.target.value);
                    setFieldValue("documentName", e.target.value);
                  }}
                  placeholder={formField?.documentName?.placeholder}
                  error={errors?.documentName && touched?.documentName}
                  success={formField?.documentName?.length > 0 && !errors?.documentName}
                  inputProps={{ autoComplete: "" }}
                />
              </Grid>
              <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                <FormField
                  label={formField?.documentDescription?.label}
                  name={formField?.documentDescription?.name}
                  type={formField?.documentDescription?.type}
                  value={values?.documentDescription}
                  disabled={values?.orderChange}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setFieldValue("documentDescription", e.target.value);
                  }}
                  placeholder={formField?.documentDescription?.placeholder}
                  error={errors?.documentDescription && touched?.documentDescription}
                  success={
                    formField?.documentDescription?.length > 0 && !errors?.documentDescription
                  }
                  inputProps={{ autoComplete: "" }}
                />
              </Grid>
              <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>Category</Typography>
                <Autocomplete
                  freeSolo
                  onChange={(event, value, reason, details) => {
                    setDocumentCategory(value);
                    setFieldValue("documentCategory", value);
                  }}
                  options={categories.map((option) => option.label)}
                  renderInput={(params) => <TextField value={values?.documentCategory} onChange={(event) => {
                    setDocumentCategory(event.target.value);
                    setFieldValue("documentCategory", event.target.value);
                  }} {...params} />}
                />
                <VuiTypography
                  mt={0}
                  variant="div"
                  color={"error"}
                  textAlign="center"
                >
                  <span>{errors.documentCategory && touched.documentCategory ? errors.documentCategory : ""}</span>
                </VuiTypography>
              </Grid>

              <Grid item xs={12} md={12}>
                <VuiTagInput placeholder="Tags" tags={tags} onChange={updateTags} />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Spin>
  );
}

export default connect((state) => ({ loading: state.CommonStore.loader }))(SelectDocument);
