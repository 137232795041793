

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// DPS UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";

// DPS UI Dashboard custom components
import GradientBorder from "examples/GradientBorder";

// DPS UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/background-cover-auth-signin.png";

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <CoverLayout
      title="Nice to see you!"
      color="dark"
      // description="Enter your email and password to sign in"
      description=""
      image={bgImage}
      premotto={"INSPIRED BY THE FUTURE:"}
      motto={"Digital Postal Service"}
    >
      <VuiBox component="form" role="form">
        <VuiBox mt={2} mb={1}>
          <VuiButton
            color="info"
            onClick={() => {
              const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
              const redirect_uri = `${location.origin}/prepare-session`;
              const REACT_APP_COGNITO_URL = process.env.REACT_APP_COGNITO_URL;

              window.location.href = `${REACT_APP_COGNITO_URL}/logout?client_id=${CLIENT_ID}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${redirect_uri}`;
            }}
            fullWidth
          >
            SIGN IN
          </VuiButton>
        </VuiBox>
      </VuiBox>
    </CoverLayout>
  );
}

export default SignIn;
