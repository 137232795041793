import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";
import axios from "axios";
import Card from "@mui/material/Card";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import moment from "moment";
import Spin from "components/Spin";
import dpslogo from "../../assets/images/dps-logo.png";
import DataTable from "examples/Tables/DataTable";
import { Typography } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Container from '@mui/material/Container';
import { GetProfile, setProfileData, setProfileDataRes, setLoaderStatus } from "redux/Actions/common";
import { connect } from "react-redux";

function ManageSubscription(props) {
  const { GetProfile, setProfileData, setProfileDataRes, setLoaderStatus } = props;
  const [loading, setLoading] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [loadingSubscription, setLoadingSubscription] = useState(null);
  const [subscriptionHistory, setSubscriptionHistory] = useState([]);
  const [customerId, setCustomerId] = useState("");

  const express_plan = {
    price: 0,
    title: "EXPRESS 1 TIME $5.00 FEE",
    tag_line: "Pay only for what you need no monthly fee",
    name: 'express',
    planName: 'Express',
    features: [
      { label: "USPS Shipping Labels up to 89% savings", includes: true },
      { label: "Document e-notary service (included)", includes: true },
      { label: "Send postal mail online (included)", includes: true },
      { label: "Certificate of mailing (optional)", includes: true },
      { label: "Certificate of delivery (optional)", includes: true },
      { label: "Blockchaing records (1 year included)", includes: true }
    ]
  };

  const merchant_plan = {
    title: "MERCHANT $10.00 PER MONTH",
    tag_line: "Perfect for small businesses $10 per month",
    price: 10,
    name: 'merchant',
    planName: 'Merchant',
    features: [
      { label: "USPS Shipping Labels up to 89% savings", includes: true },
      { label: "Document e-notary service (included)", includes: true },
      { label: "Send postal mail online (included)", includes: true },
      { label: "Certificate of mailing (included)", includes: true },
      { label: "Certificate of delivery (included)", includes: true },
      { label: "Blockchaing records (included)", includes: true }
    ]
  };

  const web3_merchant_plan = {
    title: "WEB3 MERCHANT $50.00 PER MONTH",
    tag_line: "Perfect for small brands $50 per month",
    name: 'web3_merchant',
    planName: 'WEB3 MERCHANT',
    price: 50,
    features: [
      { label: "NFT/COA Management", includes: true },
      { label: "API Integration", includes: true }
    ]
  };

  const plans = [
    express_plan,
    merchant_plan,
    web3_merchant_plan
  ]

  const getShipperDetails = () => {
    GetProfile().then((res) => {
      if (res.status === 200) {
        setProfileData(res.data[0]);
        setProfileDataRes(res.data[0]);
        setCustomerId(res.data[0]?.CustomerId);

        if (
          res.data[0]?.SubscriptionInfo != null &&
          res.data[0]?.SubscriptionInfo != undefined &&
          res.data[0]?.SubscriptionInfo?.SubscriptionId != undefined
        ) {
          setSubscriptionDetails(res.data[0]?.SubscriptionInfo);
          localStorage.setItem("SubscriptionId", res.data[0]?.SubscriptionInfo?.SubscriptionId);
        }
      }
    }).finally(() => {
      setLoaderStatus(false);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      getShipperDetails();
    }, 3000)
  }, []);


  React.useEffect(() => {

    const LoadExternalScript = () => {
      const externalScript = document.createElement("script");
      externalScript.type = "text/javascript";
      externalScript.id = "pricing_table1";
      document.body.appendChild(externalScript);
      externalScript.src = `https://js.stripe.com/v3/pricing-table.js`;


      // const externalScript1 = document.createElement("script");
      // externalScript1.type = "text/javascript";
      // externalScript1["pricing-table-id"] = "prctbl_1LgkknFCTLiv3m0LbtzyaGxp";
      // externalScript1["publishable-key"] = "pk_test_BMRihX2tqW9lZy1xKyEDJStx00W5B7wXzq";
      // externalScript1.id = "pricing_table2";
      // document.body.appendChild(externalScript1);
      // externalScript1.src = "";
    };

    LoadExternalScript();
  }, []);

  useEffect(() => {
    // getShiperDetails();
    // setTimeout(() => {
    //   getShiperDetails();
    // }, 3000);
    // SubscriptionHistory();
  }, []);


  const manageSubscription = (subscriptionType, mode) => {
    var url = `${process.env.REACT_APP_API_BASE_URL}/stripe/create-session`;
    var headers = {
      Authorization: `Bearer ${localStorage.id_token}`,
      authtype: "token",
    };
    let successUrl = `${window.location.origin}/manage-subscription?status=success`;
    let cancelUrl = `${window.location.origin}/manage-subscription?status=failed`;
    if (props?.successUrl) {
      successUrl = props?.successUrl;
    }
    if (props?.successUrl) {
      cancelUrl = props?.cancelUrl;
    }
    var payload = {
      ShipperHash: localStorage.ShipperHash,
      Mode: mode,
      // subscriptionType: subscriptionType,
      PlanType: subscriptionType,
      CancelUrl: cancelUrl,
      SuccessUrl: successUrl,
      CustomerId: customerId,
    };
    setLoading(true);
    axios
      .post(url, payload, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          window.location = res.data.SessionUrl;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };



  const managementConsole = (e) => {
    e.preventDefault();
    manageSubscription("manage", "customer_portal");
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Spin tip="Loading..." spinning={loading}>

        <Card className={"subscription_card"}>
          <h3 style={{ color: '#fff', textAlign: 'center' }}>
            {
              subscriptionDetails?.SubscriptionId != null ? "Your Subscription Details" : "Setup Your Subscription"
            }
          </h3>
          <img src="https://demos.creative-tim.com/soft-ui-dashboard-pro-react/static/media/waves-white.56607573d7f27cc7c8eb151ea3d5bc67.svg" />
        </Card>
        {/* <stripe-pricing-table
          pricing-table-id="prctbl_1LgkknFCTLiv3m0LbtzyaGxp"
          publishable-key="pk_test_BMRihX2tqW9lZy1xKyEDJStx00W5B7wXzq"
          client-reference-id={localStorage.ShipperHash}
          customer-email={props?.Profile.Email}
        >
        </stripe-pricing-table> */}

        <Container style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', top: subscriptionDetails?.SubscriptionId ? '40%' : '20%' }}>

          {subscriptionDetails &&
            subscriptionDetails?.SubscriptionId &&
            subscriptionDetails?.IsActive ? (
            <Grid container spacing={3} justifyContent="center" pt={10}>
              <Grid item xs={6.7}>
                <Card display="flex" style={{ flexDirection: "row", alignItems: "center" }}>
                  <VuiBox my={1} textAlign="center">
                    <img src={dpslogo} alt="dps-logo" style={{ width: 90, height: 80 }} />
                    <VuiTypography variant="h5" color="dark" mt={1} mr={4}>
                      <VuiTypography display="block" variant="h6" color="dark">
                        You are currently on
                      </VuiTypography>
                      {subscriptionDetails?.Title}
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox my={1} textAlign="left">
                    <VuiTypography variant="body2" color="dark">
                      <VuiTypography display="block" variant="body2" color="dark">
                        <strong>Plan start date:</strong>{" "}
                        {subscriptionDetails?.ValidFrom != "" &&
                          subscriptionDetails?.ValidFrom != null &&
                          new Date(subscriptionDetails?.ValidFrom).toString() != "Invalid Date"
                          ? moment(subscriptionDetails?.ValidFrom).format("MMM DD, YYYY")
                          : "NA"}
                      </VuiTypography>
                      <strong>Valid Till:</strong>{" "}
                      {subscriptionDetails?.ValidTill != "" &&
                        subscriptionDetails?.ValidTill != null &&
                        new Date(subscriptionDetails?.ValidTill).toString() != "Invalid Date"
                        ? moment(subscriptionDetails?.ValidTill).format("MMM DD, YYYY")
                        : "NA"}
                    </VuiTypography>
                    <VuiTypography display="block" variant="body2" color="dark">
                      <strong>Billing Cycle:</strong> {subscriptionDetails?.RecurringFrequency}
                    </VuiTypography>
                    <VuiTypography display="block" variant="body2" color="dark">
                      <strong>Status:</strong> {subscriptionDetails?.Status}
                    </VuiTypography>

                    <VuiTypography display="block" variant="body2" color="dark">
                      {subscriptionDetails?.Status != "Canceled" ? (
                        <span>
                          <strong>Next payment date:</strong>{" "}
                          {subscriptionDetails?.ValidTill &&
                            new Date(subscriptionDetails?.ValidTill).toString() != "Invalid Date"
                            ? moment(subscriptionDetails?.ValidTill).format("MMM DD, YYYY")
                            : "NA"}
                        </span>
                      ) : (
                        <span style={{ color: "red" }}>
                          Your paid subscription will automatically be canceled by end of your billing
                          cycle. You can renew your subscription before it cancels by clicking the
                          manage button. After cancellation you will be given option to switch to free
                          plan
                        </span>
                      )}
                    </VuiTypography>
                  </VuiBox>
                  {subscriptionDetails?.PlanType != "Express" ? (
                    <VuiBox mt={1} ml={3}>
                      <VuiButton
                        component="a"
                        href=""
                        color="success"
                        fullWidth
                        onClick={managementConsole}
                      >
                        Manage
                      </VuiButton>
                    </VuiBox>
                  ) : null}
                </Card>
              </Grid>
            </Grid>
          ) : null}

          {loading == false &&
            (subscriptionDetails?.PlanType == "Express" ||
              subscriptionDetails?.PlanType == null ||
              subscriptionDetails?.IsActive == false) ? (
            <Grid container spacing={3} justifyContent="center" pt={2} pb={10}>
              {
                plans?.map((plan, index) => (
                  <Grid item md={props?.isPopup ? 7 : 4} xs={props?.isPopup ? 7 : 6}>
                    <DefaultPricingCard
                      badge={{ color: "dark", label: plan.title }}
                      price={{ currency: "$", value: plan.price }}
                      specifications={plan.features}
                      style={{ minHeight: 455 }}
                      action={{
                        type: "internal",
                        route: "/",
                        name: plan.name,
                        tagLine: plan.tag_line,
                        color:
                          subscriptionDetails?.PlanType.toLowerCase() == plan.planName.toLowerCase() && subscriptionDetails?.IsActive
                            ? "success"
                            : "info",
                        label:
                          subscriptionDetails?.PlanType.toLowerCase() == plan.planName.toLowerCase() && subscriptionDetails?.IsActive
                            ? "Currently Active"
                            : "Select Plan",
                        loading: false,
                        handleClick: (name) => {
                          if (plan.planName.toLowerCase() != subscriptionDetails?.PlanType.toLowerCase() || subscriptionDetails?.IsActive == false) {
                            if (name == 'express') {
                              manageSubscription('EXPRESS', 'subscription')
                            }
                            else if (name == 'merchant') {
                              manageSubscription('MERCHANT', 'subscription')
                            }
                            else {
                              manageSubscription('WEB3 MERCHANT', 'subscription')
                            }
                          }
                        },
                      }}
                    />
                  </Grid>
                ))
              }

            </Grid>
          ) : null}
        </Container>
      </Spin>
    </Box>
  );
}


export default connect(
  (state) => ({
    Profile: state.CommonStore.Profile,
  }),
  {
    GetProfile,
    setProfileData,
    setProfileDataRes,
    setLoaderStatus
  }
)(ManageSubscription);