import { useEffect, useState } from "react";
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Wizard page components
import Account from "layouts/applications/wizard/components/Account";
import PostageType from "./PostageType";
import RecipientInfo from "layouts/applications/wizard/components/RecipientInfo";
import COM from "./COM";
import PostageSettings from "./postage_settings";

import Summary from "./Summary";
import { BiBox } from "react-icons/bi";
import { IoCube } from "react-icons/io5";
import { RiInboxFill } from "react-icons/ri";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { setActiveStep } from "redux/Actions/wizard";
import { CircularProgress } from "@mui/material";
import {
  PostVerifyAddress,
  ResetWizard,
  ActPostTopic,
  ActPostJob,
  PostPackageCheckOut,
  PostRecipient,
  PostVerifyAddressSuccess,
} from "redux/Actions/wizard";
import { setLoaderStatus } from "redux/Actions/common";
import { useLocation } from "react-router-dom";
import validations from "layouts/applications/wizard/schemas/print-2-mail-validations";
import form from "layouts/applications/wizard/schemas/print-to-mail-form";
import initialValues from "layouts/applications/wizard/schemas/initialValues";
import axios from "axios";
import { Prompt } from "react-router";
import Notification from "components/Notification";
import { RefreshProfile } from "redux/Actions/common";
import Breadcrumbs from "examples/Breadcrumbs";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';


const packageTypeStepper = [
  "General",
  "Recipient",
  "Print Options",
  "Postage",
  "Certificates",
  "Summary",
];

function getSteps(sendType) {
  if (sendType === "document") {
    return ["Upload PDF", "Document"];
  } else if (sendType === "package") {
    return;
  } else {
    return packageTypeStepper;
  }
}

function getStepContent(
  stepIndex,
  getSendNextBtnState,
  documentKey,
  documentHash,
  notraizedDocumentKey,
  notraizedDocumentHash,
  handleOnEditMailing,
  formData,
  pageCount,
  onPrint2MailAcceptable,
  checkoutProgress
) {
  switch (stepIndex) {
    case 0:
      return <Account formData={formData} handleOnEditMailing={handleOnEditMailing} documentKey={documentKey} />;
    case 1:
      return <RecipientInfo formData={formData} />;
    case 2:
      return <PostageSettings formData={formData} />;
    case 3:
      return <PostageType formData={formData} PageCount={pageCount} />;
    case 4:
      return <COM formData={formData} />;
    case 5:
      return (
        <Summary
          formData={formData}
          documentKey={documentKey}
          checkoutResponse={checkoutProgress}
          pageCount={pageCount}
        />
      );
    default:
      return null;
  }
}

const PACKAGES = [
  { name: "CUSTOM PACKAGE", icon: <IoCube size="54px" color="dark" /> },
  { name: "USPS FLAT RATE BOX", icon: <BiBox size="54px" color="dark" /> },
  { name: "SOFT PACK ENVELOPE", icon: <RiInboxFill size="54px" color="dark" /> },
];

function PrintToMailWizard(props) {
  const {
    active,
    loader,
    SelectedPackagePricingData,
    RecordManagement,
    CertificateOfMailing,
  } = props;
  const { formId, formField } = form;
  const search = useLocation().search;
  let { send_type, activeTab } = useParams();
  const currentValidation = validations[active];
  const [jobErorr, setJobError] = useState("");
  const [topicError, setTopicError] = useState("");
  const [recipientError, setRecipientError] = useState("");
  const [checkoutError, setCheckoutError] = useState("");
  const [open, setOpen] = useState(false);
  const [jobSuccess, setJobSuccess] = useState(false);
  const [topicSuccess, setTopicSuccess] = useState(false);
  const [recipientSuccess, setRecipientSuccess] = useState(false);
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);
  const [jobHash, setJobHash] = useState("");
  const [topicHash, setTopicHash] = useState("");
  const [formValues, setFormValues] = useState({});
  const [modeType, setModeType] = useState("");
  const [btnState, setBtnState] = useState(loader);
  const [selectWizard, setSelectWizard] = useState("");
  const [sendType, setSendType] = useState("");
  const [steps, setSteps] = useState([]);
  const [print2MailAcceptable, setPrint2MailAcceptable] = useState(false);

  const [notraizedDocumentKey, setNotraizedDocumentKey] = useState("");
  const [notraizedDocumentHash, setNotraizedDocumentHash] = useState("");

  const [documentKey, setDocumentKey] = useState(null);
  const [documentHash, setDocumentHash] = useState(null);

  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("success");

  const history = useHistory();

  const handleOnEditMailing = () => {
    setTopicHash("");
  };

  useEffect(() => {
    const documentKey = new URLSearchParams(search).get("k");
    const notraizedDocumentHash = new URLSearchParams(search).get("h");
    const pdfPageCount = new URLSearchParams(search).get("p");
    if (documentKey != null && documentKey != undefined) {
      setDocumentKey(documentKey);
      setNotraizedDocumentHash(notraizedDocumentHash);
      setPageCount(pdfPageCount);
    }
  }, []);

  useEffect(() => {
    if (send_type) {
      setSendType(send_type);
      var steps = getSteps(send_type);
      setSteps(steps);
      props.setLoaderStatus(false);
    }
  }, [send_type]);

  useEffect(() => {
    props.ResetWizard();
    if (activeTab) {
      props.setActiveStep(parseInt(activeTab));
    }
    var loc = history;
    if (history?.location?.state?.step != null && history?.location?.state?.step != undefined) {
      props.setActiveStep(history?.location?.state?.step);
      setPageCount(history?.location?.state?.PageCount);
      setNotraizedDocumentHash(history?.location?.state?.Id);
      setDocumentKey(history?.location?.state?.documentKey);
    }
  }, [history, activeTab, send_type]);
  const isLastStep = active === packageTypeStepper.length;

  const handleBack = () => {
    props.setActiveStep(active - 1);
  };

  const getSendNextBtnState = (status) => {
    if (status == "mail_online") {
      setBtnState(false);
      props.setActiveStep(1);
    }
    if (status == "send_epostage") {
      props.setActiveStep(active + 1);
    }
  };

  const HandlePackageCheckout = (values, jobHash, ModeType) => {
    props.setLoaderStatus(true);
    setCheckoutLoading(true);
    setCheckoutError('');
    const payload = {
      JobHash: jobHash,
      Mode: ModeType,
    };
    props.PostPackageCheckOut(payload).then((res) => {
      if (!res.status) {
        setCheckoutLoading(false);
        setCheckoutError(res.error);
      } else {
        setModeType(ModeType);
        setCheckoutSuccess(true);
        setCheckoutLoading(false);
        props.setLoaderStatus(false);
      }
      props?.RefreshProfile();
    });
  };

  const HandleRecipient = (values, jobHash) => {
    const payload = {
      JobHash: jobHash,
      CompanyName: values?.recCompanyName, //# optional by default it's empty
      Phone: "", //# optional by default it's empty
      Email: "", //# optional by default it's empty
      PersonName: values?.recipientName, //# optional by default it's empty
      AddressLine1: values?.recAddress1,
      AddressLine2: values?.recAddress2, //# optional by default it's empty
      Country: "", //# optional by default it's empty
      City: values?.recCity,
      State: values?.recState,
      Zip: values?.recZip,
    };

    setRecipientError('');
    props.PostRecipient(payload).then((res) => {
      if (!res.status) {
        setRecipientError(res.error);
      } else {
        console.log("run checkout");
        HandlePackageCheckout(values, jobHash, "Live");
        setRecipientSuccess(true);
      }
    });
  };

  const HandleJobCreate = (values, topicHash) => {
    const payload = {
      UniqueJobId: "",
      PreviousTopicHash: values?.topicHash,
      TopicHash: topicHash,
      ShipperHash: localStorage.ShipperHash,
      PostageType: SelectedPackagePricingData?.ServiceType,
      FromName: values?.senderName,
      FromCompany: values?.CompanyName,
      FromAddressLine1: values?.address1,
      FromAddressLine2: values?.address2,
      FromPhone: values.phone.substr(values.phone.length - 10),
      FromCity: values?.city,
      FromState: values?.state,
      FromZip: values?.zip,
      FromCountry: values?.country,
      Weight: values?.packageWeight, // required
      ExtraInfo: "",
      //COMCode: CertificateOfMailing?.Code,
      COMCode: CertificateOfMailing?.Code == 'No' ? '' : CertificateOfMailing?.Code,
      StorageClass: `${RecordManagement?.Code}`,
      ApiService: "Print2Mail",
      NotarizedDocumentHash: notraizedDocumentHash,
      PageCount: pageCount,
      DoubleSided: values["printType"] == "TS" ? true : false,
      Color: values["colorType"] == "C" ? true : false,
      ReturnEnvelope: values["returnEnvelope"],
    };

    setJobError('');
    props.ActPostJob(payload).then((res) => {
      if (!res.status) {
        setJobError(res.error);
      } else {
        setJobHash(res.data.JobHash);
        HandleRecipient(values, res.data.JobHash);
        setJobSuccess(true);
      }
    });
  };

  const HandleTopicCreate = (values) => {
    const payload = {
      ShipperHash: localStorage.ShipperHash,
      Name: values.caseName,
      Subject: values.mailingName,
      // ...SelectedTopic,
    };
    setTopicError('');
    props.ActPostTopic(payload).then((res) => {
      if (!res.status) {
        setTopicError(res.error);
      } else {
        setTopicSuccess(true);
        setTopicHash(res.data.TopicHash);
        HandleJobCreate(values, res.data.TopicHash);
      }
    });
  };

  const submitForm = async (values) => {
    setOpen(true);
    if (topicHash) {
      await setTopicSuccess(true);
    } else {
      await setTopicSuccess(false);
    }

    if (topicHash && !jobSuccess) {
      setTopicSuccess(true);
      HandleJobCreate(values, topicHash);
    } else if (jobSuccess && !recipientSuccess) {
      HandleRecipient(values, jobHash);
    } else if (jobSuccess && recipientSuccess && !checkoutSuccess) {
      HandlePackageCheckout(values, jobHash, "Live");
    } else if (jobSuccess && recipientSuccess && checkoutSuccess) {
      setOpen(true);
    } else {
      if (topicSuccess) {
        HandleJobCreate(values, topicHash);
      } else {
        if (!topicHash) {
          HandleTopicCreate(values);
        }
      }
    }
  };


  const [breadcumbsRoute, setBreadcumbsRoute] = useState([]);

  const getPackageTypeStepper = () => {
    let arr = [];
    for (let i = 0; i <= active; i++) {
      arr.push(packageTypeStepper[i]);
    }
    arr.push('');
    setBreadcumbsRoute(arr);
  }

  useEffect(() => {
    getPackageTypeStepper();
  }, [active]);

  const handleSubmit = (values, actions) => {
    // if (isLastStep) {
    if (active === 5) {
      if (topicSuccess && jobSuccess && checkoutSuccess && recipientSuccess) {
        history.push("/dashboard");
      } else {
        setFormValues(values);
        setCheckoutConfirmDialogOpen(true);
        // submitForm(values);
      }
    }
    else if (active === 0) {
      props.setActiveStep(active + 1);

    } else if (active === 1) {
      verifyAddress(values);

      // if (!addressVerified) {
      //   verifyAddress(values);
      // }
      // else {
      //   props.setActiveStep(active + 1);
      // }
    } else {
      props.setActiveStep(active + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const onPrint2MailAcceptable = (flag) => {
    setPrint2MailAcceptable(flag);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMessageOpen(false);
  };

  setTimeout(() => {
    if (messageOpen) {
      setMessageOpen(false);
    }
  }, 6000);

  const [checkoutConfirmDialogOpen, setCheckoutConfirmDialogOpen] = useState(false);

  const handleCheckoutConfirmClose = (event) => {
    setCheckoutConfirmDialogOpen(false);
    if (event == 'agree') {
      submitForm(formValues);
    }
  }

  const handleFinalDialogClosing = () => {
    history.push('/track');
  }

  const [verificationDialog, setVerificationDialog] = useState(false);
  const [verifiedAddress, setVerifiedAddress] = useState(null);
  const [addressVerified, setAddressVerified] = useState(false);

  const toggleVerificationDialog = () => {
    setVerificationDialog(prevCheck => !prevCheck);
  };
  const correctAddress = (setFieldValue) => {
    setFieldValue("recAddress1", verifiedAddress.AddressLine1);
    setFieldValue("recAddress2", verifiedAddress.AddressLine2);
    setFieldValue("recCity", verifiedAddress.City);
    setFieldValue("recState", verifiedAddress.State);
    setFieldValue("recZip", verifiedAddress.Zip5);
    toggleVerificationDialog();
    setAddressVerified(true);
    props.setActiveStep(active + 1);
  }

  const verifyAddress = (values) => {
    // IsAddressChanged

    var reciepientInfo = {
      Address1: values.recAddress1,
      Address2: values.recAddress2,
      City: values.recCity,
      CompanyName: values.recCompanyName,
      PersonName: values.recipientName,
      Phone: values.phone,
      State: values.recState,
      Zip: values.recZip,
      Provider: "usps",
    };

    props.PostVerifyAddress(reciepientInfo).then((res) => {
      props.setLoaderStatus(false);
      if (res.status === 200) {
        if (res.data.IsAddressChanged == false) {
          // Entered address is correct.
          setMessageOpen(true);
          setMessage("Address verified!");
          setMessageType("success");
          props.setActiveStep(active + 1);
        }
        else {
          // entered address is incorrect need to change.
          setVerifiedAddress(res.data);
          toggleVerificationDialog();
        }
      } else {
        try {
          var error = `${res.response.data.error}`;

          setMessageOpen(true);
          setMessage(error);
          setMessageType("error");
          return false;
        } catch {
          setMessageOpen(true);
          setMessage("network error!");
          setMessageType("error");
        }
      }
    });
  }

  return (
    <>
      {
        messageType != "" ? (
          <Notification
            open={messageOpen}
            type={messageType}
            message={message}
            handleClose={handleClose}
            vertical="bottom"
            horizontal="center"
          />
        ) : null
      }

      <Prompt
        when={
          topicSuccess == false ||
          checkoutSuccess == false ||
          recipientSuccess == false ||
          jobSuccess == false
        }
        message="Your unsaved changes will be lost if you navigate away from this page. Please OK to move out of this page. Press CANCEL to continue editing"
      />

      <Dialog
        open={topicSuccess && jobSuccess && recipientSuccess && checkoutSuccess}
        // open={open}
        disableEscapeKeyDown={true}
        onClose={() => { handleFinalDialogClosing() }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Job Created
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ color: '#000000' }}>
            <p>Job created succesfully.</p>
            <p>
              Please note: It may take upto 60 seconds for order status to get updated in tracking area
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { handleFinalDialogClosing() }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={checkoutConfirmDialogOpen}
        onClose={() => { handleCheckoutConfirmClose('cancel') }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Checkout Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ color: '#000000' }}>
            Once job is submitted all orders are Final and nonrefundable. Request to stop a
            mailing may be submitted however we can not guarntee the mailing will not be
            mailed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { handleCheckoutConfirmClose('cancel') }}>Cancel</Button>
          <Button onClick={() => { handleCheckoutConfirmClose('agree') }} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <VuiBox pt={3} pb={10} style={{ width: "80%", margin: "0px auto" }}>
        <Grid container justifyContent="center" className="Suman">
          <Grid item xs={12} md={12} lg={12}>
            <VuiBox mt={1} mb={0} textAlign="center">
              <VuiBox mb={0}>
                <VuiTypography variant="h3" fontWeight="bold" color="dark">
                  PRINT 2 MAIL
                </VuiTypography>
              </VuiBox>
            </VuiBox>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={(values, actions) => {
                setTopicHash(values?.topicHash || topicHash);
                handleSubmit(values, actions);
              }}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setFieldError,
                setTouched
              }) => (
                <div>
                  <Dialog onClose={toggleVerificationDialog} maxWidth="md" fullWidth={true} open={verificationDialog}>
                    <DialogTitle>Address Modified By USPS Verification Service</DialogTitle>
                    <DialogContent style={{ width: "100%", padding: 10 }}>
                      <div style={{ paddingBottom: 10 }}>Address Line 1: {verifiedAddress?.AddressLine1}</div>

                      <div style={{ paddingBottom: 10 }}>Address Line 2: {verifiedAddress?.AddressLine2}</div>

                      <div style={{ paddingBottom: 10 }}>City: {verifiedAddress?.City}</div>

                      <div style={{ paddingBottom: 10 }}>State: {verifiedAddress?.State}</div>

                      <div>Zip: {verifiedAddress?.Zip5}</div>
                    </DialogContent>
                    <DialogActions>
                      <VuiButton variant="gradient" color="light" onClick={toggleVerificationDialog}>
                        Edit Manually
                      </VuiButton>

                      <VuiButton variant="gradient" color="dark" onClick={() => {
                        correctAddress(setFieldValue)
                      }}>
                        Use Verified Address
                      </VuiButton>
                    </DialogActions>
                  </Dialog>

                  <Form id={formId} autoComplete="off">
                    <Card>
                      {
                        breadcumbsRoute.length > 0 ? (
                          <Breadcrumbs
                            icon="home"
                            title={packageTypeStepper[active]}
                            route={breadcumbsRoute}
                          />
                        ) : null
                      }

                      <VuiBox p={2}>
                        <VuiBox>
                          {getStepContent(
                            active,
                            getSendNextBtnState,
                            documentKey,
                            documentHash,
                            notraizedDocumentKey,
                            notraizedDocumentHash,
                            handleOnEditMailing,
                            {
                              values,
                              touched,
                              formField,
                              errors,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError,
                              setTouched
                            },
                            pageCount,
                            onPrint2MailAcceptable,
                            {
                              topicSuccess,
                              jobSuccess,
                              checkoutSuccess,
                              recipientSuccess,
                              topicError,
                              recipientError,
                              jobErorr,
                              checkoutError,
                              open,
                              loader
                            }
                          )}
                          <VuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
                            {active === 0 ? (
                              <VuiBox />
                            ) : (
                              <VuiButton variant="gradient" color="light" onClick={handleBack}>
                                back
                              </VuiButton>
                            )}

                            <VuiButton
                              variant="contained"
                              color="info"
                              type="submit"
                              disabled={loader}
                            >
                              {active != 5
                                ? "Next "
                                : topicSuccess && jobSuccess && checkoutSuccess && recipientSuccess
                                  ? "Close "
                                  : "Checkout "}
                              {loader ? (
                                <CircularProgress
                                  color={"error"}
                                  size={15}
                                  style={{ marginLeft: 5 }}
                                />
                              ) : (
                                ""
                              )}
                            </VuiButton>
                          </VuiBox>
                        </VuiBox>
                      </VuiBox>
                    </Card>
                  </Form>

                </div>
              )}
            </Formik>
          </Grid>
        </Grid>
      </VuiBox>
    </>
  );
}

export default connect(
  (state) => ({
    active: state.WizardStore.activeStep,
    senderInfoData: state.WizardStore.sender_info,
    isAddressVerify: state.WizardStore.is_address_verify,
    AddressVerifyRes: state.WizardStore.address_verify_res,
    loader: state.CommonStore.loader,
    recipientInfo: state.WizardStore.recipient_info,
    packageType: state.WizardStore.packageType,
    SelectedTopic: state.WizardStore.SelectedTopic,
    TopicSuccess: state.WizardStore.TopicSuccess,
    JobResponseSuccess: state.WizardStore.JobResponseSuccess,
    PackageCheckOutRes: state.WizardStore.PackageCheckOutRes,
    PackageCheckOutSuccess: state.WizardStore.PackageCheckOutSuccess,
    RecipientSuccess: state.WizardStore.RecipientSuccess,
    SelectedPackagePricingData: state.WizardStore.SelectedPackagePricingData,
    RecordManagement: state.WizardStore.RecordManagement,
    CertificateOfMailing: state.WizardStore.CertificateOfMailing,

    TopicError: state.WizardStore.TopicError,
    RecipientError: state.WizardStore.RecipientError,
    JobResponseError: state.WizardStore.JobResponseError,
    PackageCheckOutError: state.WizardStore.PackageCheckOutError,
    JobResponse: state.WizardStore.JobResponse,
    packageData: state.WizardStore.packageData,
  }),
  {
    setActiveStep,
    PostVerifyAddress,
    ResetWizard,
    ActPostTopic,
    ActPostJob,
    PostPackageCheckOut,
    PostRecipient,
    setLoaderStatus,
    PostVerifyAddressSuccess,
    RefreshProfile,
  }
)(PrintToMailWizard);
